import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from '~/router/index.js';
import './css/index.css'
import axios from 'axios'
import { VueMaskDirective } from 'v-mask';

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

axios.defaults.baseURL = 'https://monteseubolo.com/admin'
// axios.defaults.baseURL = 'http://localhost:80/admin'

createApp(App)
  .use(router)
  .directive('mask', vMaskV3)
  .use(createPinia())
  .mount('#app')