import { defineStore } from "pinia";

export const useShopCartStore = defineStore("shop_cart", {
  state: () => {
    return {
      shop_cart: []
     };
  },
  actions: {
    setShopCart(value) {
      this.shop_cart = value
    },
    resetShopCart() {
      this.shop_cart = [];
    }
  },
  getters: {
    getShopCart: (state) => {
      return state.shop_cart;
    }
  },
});