<template>
  <section class="bg-[#f4f3ef] flex justify-center pt-16">
    <div class="flex flex-wrap w-full content-start pb-40 overflow-y-scroll">
      <div class="fixed w-full top-20 bg-[#f57f7f] bg-opacity-50 flex items-center">
        <button
          class="w-full md:w-auto px-10 md:px-16 h-16 flex justify-center items-center cursor-pointer hover:bg-[#82afd87f] transition-all text-white uppercase"
          :class="{ 'bg-[#f57f7f]' : active_tab === 'delivery' }"
          @click="active_tab = 'delivery'"
        >ENTREGA</button>
        <button
          class="w-full md:w-auto px-10 md:px-16 h-16 flex justify-center items-center cursor-pointer hover:bg-[#82afd87f] transition-all text-white uppercase"
          :class="{ 'bg-[#f57f7f]' : active_tab === 'withdrawal' }"
          @click="active_tab = 'withdrawal'"
        >RETIRADA</button>
      </div>

      <section-delivery
        @input-address="(item) => handleAddress(item)"
        v-if="active_tab === 'delivery'"
      ></section-delivery>

      <section-withdrawal
        v-if="active_tab === 'withdrawal'"
      ></section-withdrawal>

      <section-schedule
        :schedule="schedule"
        :cart="cart"
        @input-schedule="(item) => handleSchedule(item)"
      ></section-schedule>

      <section-coupon
        @input-coupon="(item) => handleCoupon(item)"
      ></section-coupon>

      <section-payment
        @input-payment="(item) => handlePayment(item)"
        :payment="payment"
      ></section-payment>
    </div>
    <Footer
      :active_tab="active_tab"
      :address="address"
      :schedule="schedule"
      :disabled_submit="disabled_submit"
      :payment="payment"
      :coupon="coupon"
      @handle-submit="(item) => handleSubmit(item)"
    ></Footer>
    <modal-simple-text
      ref="ModalSimpleText"
      :show_footer="false"
    ></modal-simple-text>
  </section>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useShopCartStore } from "~/stores/shopCartStore"
import { useUserStore } from "~/stores/userStore"
import { usePaymentStore } from "~/stores/paymentStore"
import ModalSimpleText from '@components/ModalSimpleText.vue';
import SectionDelivery from './delivery/Index.vue';
import SectionWithdrawal from './withdrawal/Index.vue';
import SectionSchedule from './schedule/Index.vue';
import SectionCoupon from './coupon/Index.vue';
import SectionPayment from './payment/Index.vue';
import Footer from './Footer.vue';
moment.locale('pt-br');

export default {
  name: 'ConfirmationIndex',
  components: {
    SectionDelivery,
    SectionWithdrawal,
    SectionSchedule,
    SectionCoupon,
    SectionPayment,
    Footer,
    ModalSimpleText
  },
  data() {
    return {
      active_tab: 'delivery',
      address: [],
      schedule: null,
      time_to_produce: null,
      cart: useShopCartStore().getShopCart,
      disabled_submit: false,
      payment: null,
      coupon: {}
    }
  },
  methods: {
    async handleSubmit(prices) {
      this.disabled_submit = true;
      await axios.put("/api/sales/create-sale-order", {
        user_id: useUserStore().getUser.id,
        schedule: moment(this.schedule).format('YYYY-MM-DD HH:mm:ss'),
        address: this.address,
        cart: this.cart,
        prices: prices,
        time_to_produce: this.time_to_produce,
        payment: this.payment,
        coupon: this.coupon
      })
      .then(({data}) => {
        useShopCartStore().setShopCart({});
        this.handleFinalize(data, prices);
      });
    },
    handleFinalize(data, prices) {
      if(this.payment.type === 'on_pickup') {
        this.$refs.ModalSimpleText.title = 'Sucesso';
        this.$refs.ModalSimpleText.message = 'Seu pedido foi agendado com sucesso!';
        this.$refs.ModalSimpleText.showModal()
        setTimeout(() => {
          this.$router.push({ name: 'OrdersView' })
        }, (3 * 1000));
      } else {
        usePaymentStore().setPayment({
          order_id: data.id,
          value: prices.total,
          payment_type: this.payment.type,
          credit_debit_card: this.payment.credit_debit_card
        })
        this.$router.push({ name: 'PaymentView' });
      }
    },
    handleAddress(item) {
      this.address = item;
    },
    handleSchedule(item) {
      this.schedule = item.schedule;
      this.time_to_produce = item.time_to_produce;
    },
    handlePayment(item) {
      this.payment = item;
    },
    handleCoupon(item) {
      this.coupon = item;
    }
  },
  watch: {
    active_tab: {
      handler: function() {
        this.address = [];
      },
      deep: true
    },
    schedule: {
      handler: function(value) {
        if(value) {
          setTimeout(() => {
            this.handleSchedule({
              schedule: null,
              time_to_produce: null
            });
          }, (240 * 1000));
        }
      },
      deep: true
    },
  }
}
</script>

<style>
</style>
