<template>
  <div
    class="w-full flex flex-col items-center gap-4 transition-all"
    id="section-customer-create-user"
  >
    <logo-rounded :classes="'w-32 mt-[-6rem]'"></logo-rounded>
    <input
      id="section-customer-create-user-first-name"
      class="border-none w-full rounded p-4"
      placeholder="Nome *"
      type="text"
      maxlength="255"
      v-model="user.first_name"
    />
    <input
      id="section-customer-create-user-last-name"
      class="border-none w-full rounded p-4"
      placeholder="Sobrenome *"
      type="text"
      maxlength="255"
      v-model="user.last_name"
    />
    <input
      id="section-customer-create-user-date-of-birth"
      class="border-none w-full rounded p-4"
      type="text"
      placeholder="Data de Nascimento *"
      v-mask="'##/##/####'"
      v-model="user.date_of_birth"
    />
    <input
      id="section-customer-create-user-password"
      ref="sectionCustomerCreateUserPassword"
      class="border-none w-full rounded p-4"
      placeholder="Crie uma senha *"
      type="password"
      maxlength="40"
      v-model="user.password"
    />
    <div class="flex w-full">
      <input
        @click="togglePassword(false)"
        id="section-customer-create-user-password-checkbox"
        ref="sectionCustomerCreateUserPasswordCheckbox"
        type="checkbox"
        value=""
        class="w-4 h-4 text-[#f57f7f] bg-gray-100 border-gray-300 rounded"
      >
      <label
        @click="togglePassword(true)"
        for="checked-checkbox"
        class="ms-2 text-sm font-medium text-gray-400"
      >Mostrar senha</label>
    </div>
    <button
      @click="handleSubmit"
      :disabled="!validateUserInformations"
      id="section-customer-create-user-button-send"

      class="w-full disabled:bg-opacity-20 p-4 justify-center cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase"
    >
      Criar cadastro
    </button>
  </div>
</template>

<script>
import LogoRounded from '@components/LogoRounded.vue';

export default {
  name: 'SectionCreateUser',
  data(){
    return {
      user: {
        id: 1,
        first_name: '',
        last_name: '',
        password: '',
        date_of_birth: null
      }
    }
  },
  components: {
    LogoRounded,
  },
  methods: {
    togglePassword(check) {
      if(check) {
        this.$refs.sectionCustomerCreateUserPasswordCheckbox.checked = !this.$refs.sectionCustomerCreateUserPasswordCheckbox.checked;
      }
      if (this.$refs.sectionCustomerCreateUserPassword.type === "password") {
        this.$refs.sectionCustomerCreateUserPassword.type = "text";
      } else {
        this.$refs.sectionCustomerCreateUserPassword.type = "password";
      }
    },
    async handleSubmit() {
      this.$emit('handleUser', this.user);
    }
  },
  computed: {
    validateUserInformations() {
      if (
        this.user.first_name.length &&
        this.user.last_name.length &&
        this.user.date_of_birth &&
        this.user.password.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>
</style>
