import { createWebHistory, createRouter } from "vue-router";
import HomeView from "~/views/HomeView.vue";
import ProductView from "~/views/ProductView.vue";
import CartView from "~/views/CartView.vue";
import SigninView from "~/views/SigninView.vue";
import CustomerView from "~/views/CustomerView.vue";
import ConfirmationView from "~/views/ConfirmationView.vue";
import OrdersView from "~/views/OrdersView.vue";
import PaymentView from "~/views/PaymentView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView
  },
  {
    path: "/product",
    name: "ProductView",
    component: ProductView,
    props: true
  },
  {
    path: "/cart",
    name: "CartView",
    component: CartView,
    props: true
  },
  {
    path: "/signin",
    name: "SigninView",
    component: SigninView,
  },
  {
    path: "/customer",
    name: "CustomerView",
    component: CustomerView,
  },
  {
    path: "/confirmation",
    name: "ConfirmationView",
    component: ConfirmationView,
  },
  {
    path: "/orders",
    name: "OrdersView",
    component: OrdersView,
  },
  {
    path: "/payment",
    name: "PaymentView",
    component: PaymentView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;