export default {
  computed: {
    cartTotalPrice() {
      return this.cart_products.reduce((total, item) => {
        return total + (item.price * (item.quantity ?? 1)) + 
          (Object.keys(item.optionals).reduce((total_opcionals, key) => {
            return total_opcionals + item.optionals[key].reduce((total, item) => {
              return total + (parseFloat(item.price ?? 0) * (item.quantity ?? 1));
            }, 0)
          }, 0));
      }, 0);
    },
  }
};
