<template>
  <section
    v-if="product_selected?.id"
    class="bg-[#f4f3ef]"
    id="section-product"
  >
    <div class="px-4">
      <div class="w-full flex flex-wrap justify-center">
        <img id="section-product-image" class="rounded max-w-xs w-80 max-h-[28rem] object-cover" :src="`https://monteseubolo.com/admin/storage/products_images/${product_selected.id}/${product_selected.cover_image}`">
        <p id="section-product-title" class="w-full pt-4 font-bold">{{ product_selected.title }} ({{ product_selected.category.title }})</p>
        <p id="section-product-description" class="w-full">{{ product_selected.description }}</p>
      </div>
      <div class="flex flex-wrap my-4">
        <a
          @click="addInCart"
          id="section-product-add-cart"
          class="cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] w-full px-4 py-2 tracking-wider text-white block uppercase"
        >
          Colocar no carrinho
        </a>
      </div>

      <opcional-item
        v-for="(item, index) in product_selected.optionals" :key="item.id"
        :opcionals_items="item.optionals"
        :limit="item.limit"
        :name="index"
        @change-optional="(items) => manipulateOpcionals(index, items)"
      ></opcional-item>

      <div class="flex flex-wrap mt-4 mb-4">
        <label for="section-product-observation" class="block mb-2 text-sm font-medium">Observações do pedido</label>
        <textarea
          id="section-product-observation"
          v-model="product_form.observations"
          rows="4"
          class="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border"
          placeholder="Digite aqui..."
        ></textarea>
      </div>
      <div class="flex flex-wrap my-4">
        <a
          @click="addInCart"
          id="section-product-add-cart"
          class="cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] w-full px-4 py-2 tracking-wider text-white block uppercase"
        >
          Colocar no carrinho
        </a>
      </div>
    </div>
  </section>
  <section v-else class="bg-[#f4f3ef] flex justify-center w-full h-full">
    <no-products-selected></no-products-selected>
  </section>
</template>

<script>
import { useShopCartStore } from "~/stores/shopCartStore"
import axios from 'axios';
import OpcionalItem from './OpcionalItem.vue';
import NoProductsSelected from '@components/NoProductsSelected.vue';

export default {
  components: {
    OpcionalItem,
    NoProductsSelected
  },
  data() {
    return {
      product_form: {},
      product_selected: {}
    }
  },
  created() {
    if(history.state.product_id_to_view) {
      this.fetchProduct(history.state.product_id_to_view);
    } else {
      this.$router.push({
        name: 'HomeView',
      })
    }
  },
  name: 'ProductIndex',
  methods: {
    addInCart() {
      let shop_cart = [...Object.values( useShopCartStore().getShopCart )];
      shop_cart.push(this.product_form);
      useShopCartStore().setShopCart(shop_cart);
      this.$router.push({
        name: 'CartView',
      })
    },
    async fetchProduct(product_id) {
      await axios.get("/api/products/get-by-id/" + product_id)
        .then(({data}) => {
          this.product_selected = data;
          this.product_form = {
            id: data.id,
            title: data.title,
            description: data.description,
            price: data.price,
            cover_image: data.cover_image,
            category: data.category,
            minutes_to_produce: data.minutes_to_produce,
            optionals: {},
            observations: null
          };
        });
    },
    manipulateOpcionals(index, item) {
      this.product_form.optionals[index] = item;
    }
  }
}
</script>

<style>
</style>
