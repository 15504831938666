<template>
  <section class="bg-[#f4f3ef] flex flex-col p-8 items-center justify-center w-full h-full gap-4">
    <img
      class="w-2/3"
      ref="pix_image"
      src=""
    >
    <p ref="date_to_refresh" class="text-[#f57f7f] text-2xl"></p>
    <textarea v-model="pix_copia_cola" class="w-full h-32 p-4 rounded-lg border-none"></textarea>
    <button
      @click="handleCopyToClipboard"
      class="w-full bg-[#82afd87f] border-[#82afd87f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
    >
      Copiar PIX Copia e Cola
    </button>
    <button
      @click="confirmPayment"
      class="w-full flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white rounded-md"
    > 
      Confirmar Pagamento
      <svg class="fill-[#fff] w-6" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
      </svg>
    </button>
    <modal-simple-text
      ref="ModalSimpleText"
      :show_footer="false"
    ></modal-simple-text>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { usePaymentStore } from "~/stores/paymentStore"
import ModalSimpleText from '@components/ModalSimpleText.vue';
moment.locale('pt-br');

export default {
  name: 'PixIndex',
  components: {
    ModalSimpleText
  },
  data() {
    return {
      payment: usePaymentStore().getPayment,
      pix_copia_cola: null,
      id: null
    }
  },
  mounted() {
    this.handlePayment();
  },
  methods: {
    handleCopyToClipboard() {
      navigator.clipboard.writeText(this.pix_copia_cola);
      alert('Copiado para a área de transferência!');
    },
    async handlePayment() {
      await axios.get("/api/checkout/generate-pix-by-sale-order-id/" + this.payment.order_id)
        .then(({data}) => {
          this.$refs.date_to_refresh.innerHTML = 'Válido até ' + moment(data.expirationDate).format('LLL');
          this.$refs.pix_image.src = 'data:image/png;base64,' + data.encodedImage;
          this.pix_copia_cola = data.payload;
        });
    },
    async confirmPayment() {
      await axios.get("/api/checkout/get-by-sale-order-id/" + this.payment.order_id)
        .then(({data}) => {
          if(data.date_approved) {
            this.$refs.ModalSimpleText.title = 'Sucesso';
            this.$refs.ModalSimpleText.message = 'Seu pedido foi agendado com sucesso!';
            this.$refs.ModalSimpleText.showModal()
            setTimeout(() => {
              this.$router.push({ name: 'OrdersView' })
            }, (3 * 1000));
          } else {
            alert('Pagamento não foi confirmado. Por favor, tente novamente.');
          }
        });
    }
  },
}
</script>

<style>
</style>
