<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-cart/>
  </main>
</template>

<script>
import SectionCart from '@layouts/section-cart/Index.vue';

export default {
  components: {
    SectionCart
  }
}
</script>