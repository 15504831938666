<template>
  <section class="bg-[#f4f3ef] w-full">
    <div v-if="!sales_orders.length" class="w-full flex flex-col items-center justify-center">
      <svg class="w-20 h-20 fill-[#f57f7f] text-gray-500" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13C12.48 2.7 9.07 6 7 8c-1.07 1.07-2.1 1.1-3.09.91C4.48 11.53 8 15.59 12 21c1.1-1.07 2.1-2.1 3.09-3.09C15.59 18.52 17.42 12 12 5z"></path>
      </svg>
      <p>
        Nenhum pedido encontrado
      </p>
    </div>
    <div v-for="sale_order in sales_orders" :key="sale_order.id">
      <div class="w-full product-section">
        <button
          @click="handleClick(sale_order)"
          class="w-full text-left border-l-[#82afd87f] border-l-[12px] flex border-solid items-center transition-all hover:bg-[#fcfcfc]"
        >
          <div class="w-full flex flex-wrap content-center p-4 gap-2">
            <span class="w-full flex items-center gap-2">
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M5 8h14V6H5z" opacity=".3"></path><path d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path>
              </svg>
              <div class="flex flex-col">
                <p>Pedido em</p>
                {{ moment(sale_order.created_at).format('LLL') }}
              </div>
            </span>
            <span v-if="sale_order.payments.date_approved" class="w-full flex items-center gap-2">
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M5 8h14V6H5z" opacity=".3"></path><path d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path>
              </svg>
              <div class="flex flex-col">
                <p>Previsão</p>
                {{ moment(sale_order.schedules.end).format('LLL') }}
              </div>
            </span>
            <p v-if="sale_order.payments.date_approved" class="w-full flex items-center gap-2">
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
              </svg>
              R$ {{ sale_order.total }}
            </p>
            <p v-else-if="moment(sale_order.created_at).add(900, 'seconds').isAfter(moment())" class="w-full flex items-center gap-2">
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
              </svg>
              Pagamento pendente
            </p>
            <p v-else class="w-full flex items-center gap-2">
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
              </svg>
              Pedido não pago
            </p>
          </div>
          <div v-if="sale_order.payments.date_approved" class="w-full flex flex-wrap content-center p-4 pr-8 gap-2">
            <span class="w-full flex items-center justify-end gap-2">
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M16.49 13.75L14 16.25l2.49 2.5V17H22v-1.5h-5.51zm3.02 6H14v1.5h5.51V23L22 20.5 19.51 18zM7.5 3.5c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm2.05 6.9C10.85 12 12.8 13 15 13v-2c-1.85 0-3.45-1-4.35-2.45l-.95-1.6C9.35 6.35 8.7 6 8 6c-.25 0-.5.05-.75.15L2 8.3V13h2V9.65l1.75-.75L3 23h2.1l1.75-8L9 17v6h2v-7.55L8.95 13.4l.6-3z"></path>
              </svg>
              {{ sale_order.street ? 'Entrega' : 'Retirada' }}
            </span>
            <span v-if="!sale_order.street" class="w-full flex items-center justify-end gap-2">
              <button
                @click="handleAddress"
                class="bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Ver Endereço
              </button>
            </span>
          </div>
        </button>
      </div>
    </div>
    <modal-view-order
      ref="ModalViewOrder"
    ></modal-view-order>
  </section>
</template>

<script>
import { usePaymentStore } from "~/stores/paymentStore"
import moment from 'moment';
import ModalViewOrder from './ModalViewOrder.vue';
moment.locale('pt-br');

export default {
  name: 'HomeIndex',
  data() {
    return {
      moment: moment
    }
  },
  components: {
    ModalViewOrder
  },
  methods: {
    handleClick(sale_order) {
      if(
        (
          !sale_order.payments.date_approved && moment(sale_order.created_at).add(900, 'seconds').isAfter(moment())
        ) && sale_order.payments.type === 'pix'
      ) {
        usePaymentStore().setPayment({
          order_id: sale_order.id,
          value: sale_order.total,
          payment_type: sale_order.payments.type
        })
        this.$router.push({ name: 'PaymentView' });
      } else {
        this.$refs.ModalViewOrder.showModal(sale_order);
      }
    },
    handleAddress() {
      window.open('https://www.google.com/maps/dir//1089,+R.+Gaspar+Silveira+Martins,+1005+-+Margarida,+Santa+Cruz+do+Sul+-+RS/@-29.7053132,-52.5065136,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x951ca33639d38941:0x88edbb10805d864e!2m2!1d-52.4241497!2d-29.7053457?entry=ttu', '_blank');
    }
  },
  props: {
    sales_orders: {
      type: Array,
      default: () => []
    }
  }
}
</script>