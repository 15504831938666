import { defineStore } from "pinia";

export const useCompanySettingsStore = defineStore("company_settings", {
  state: () => {
    return {
      company_settings: {}
     };
  },
  actions: {
    setCompanySettings(value) {
      this.company_settings = value
    }
  },
  getters: {
    getCompanySettings: (state) => {
      return state.company_settings;
    }
  },
});