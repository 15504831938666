<template>
  <header class="absolute w-full flex justify-center flex-wrap bg-[#f4f3ef] h-20 z-50">
    <div class="flex flex-wrap items-center relative my-[20px] justify-between w-full px-8">
      <div class="cursor-default transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase">
        <span>{{ handlePageName }}</span>
      </div>
      <modal-informations
        v-if="!useUserStore.getUser?.id"
      ></modal-informations>
      <modal-customer-informations
        v-else
      ></modal-customer-informations>
    </div>

    <div class="triangle">&nbsp;</div>
  </header>
</template>
<script>
import { useUserStore } from "~/stores/userStore"
import ModalInformations from './ModalInformations.vue';
import ModalCustomerInformations from './ModalCustomerInformations.vue';

export default {
  name: 'HeaderIndex',
  data() {
    return {
      useUserStore: useUserStore()
    }
  },
  components: {
    ModalInformations,
    ModalCustomerInformations
  },
  computed: {
    handlePageName() {
      switch (this.$route.name) {
        case 'HomeView':
          return 'Cardápio';
        case 'ProductView':
          return 'Produtos';
        case 'CartView':
          return 'Carrinho';
        case 'SigninView':
          return 'Entrar';
        case 'CustomerView':
          return 'Meus Dados';
        case 'ConfirmationView':
          return 'Confirmação';
        case 'OrdersView':
          return 'Meus Pedidos';
      }
      return 'Cardápio';
    }
  }
}
</script>