<template>
  <div class="w-full flex flex-col items-center transition-all">
    <div class="w-full flex flex-wrap">
      <div
        @click="$refs.ModalConfirmAddress.showModal()"
        class="w-full text-left h-28 flex items-center py-4 transition-all hover:bg-[#fcfcfc] cursor-pointer"
      >
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-opacity=".3" d="M18.5 10.2c0 2.57-2.1 5.79-6.16 9.51l-.34.3-.34-.31C7.6 15.99 5.5 12.77 5.5 10.2c0-3.84 2.82-6.7 6.5-6.7s6.5 2.85 6.5 6.7z"></path><path d="M12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2zm6 8.2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
          </svg>
        </div>
        <div class="w-full flex flex-wrap content-center">
          <p class="w-full text-black">Me entregue em </p>
          <div v-if="!address?.id">
            <p class="w-full">Selecione um endereço</p>
          </div>
          <div v-else>
            <p>{{ address.nickname }}</p>
            <p>{{ address.street }}, {{ address.number }} {{ address.complement ? '(' + address.complement + ')' : '' }} - {{ address.neighborhood.name }}</p>
            <p>{{ address.city.name }} {{ address.city.state.code ? ' / ' + address.city.state.code : '' }}</p>
          </div>
        </div>
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
          </svg>
        </div>
      </div>
    </div>
    <modal-confirm-address
      @input-address="(item) => handleAddress(item)"
      ref="ModalConfirmAddress"
    ></modal-confirm-address>
  </div>
</template>

<script>
import ModalConfirmAddress from './ModalConfirmAddress.vue';

export default {
  name: 'SectionDelivery',
  components: {
    ModalConfirmAddress
  },
  data() {
    return {
      address: []
    }
  },
  methods: {
    handleAddress(item) {
      this.address = item;
      this.$emit('input-address', this.address);
    }
  },
}
</script>

<style>
</style>
