<template>
  <div>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-full max-w-lg p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
          <div class="w-full flex flex-wrap pt-2 overflow-y-auto text-left gap-4">
            <div class="w-full flex justify-between items-center px-4">
              <a class="text-[#f57f7f] text-xs md:text-base transition-all tracking-wider uppercase hover:text-[#f57f7f] pointer-events-none">
                Escolha o endereço
              </a>
              <button
                @click="this.is_open = !this.is_open"
                class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Fechar
              </button>
            </div>
          </div>
          <div class="w-full flex flex-wrap pt-2 pb-4 text-left gap-4">
            <div
              v-if="!addresses.length"
              class="flex gap-4 text-[#f57f7f] text-xs md:text-base transition-all tracking-wider pointer-events-none"
            >
              <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-opacity=".3" d="M18.5 10.2c0 2.57-2.1 5.79-6.16 9.51l-.34.3-.34-.31C7.6 15.99 5.5 12.77 5.5 10.2c0-3.84 2.82-6.7 6.5-6.7s6.5 2.85 6.5 6.7z"></path><path d="M12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2zm6 8.2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
              </svg>
              Sem endereços cadastrados
            </div>
            <div
              v-else
              v-for="(address, index) in addresses"
              :key="index"
              @click="handleAddressToDelivery(index)"
              class="w-full flex items-center transition-all hover:bg-[#fcfcfc] cursor-pointer p-4"
            >
              <div class="flex flex-wrap content-center justify-center pl-0 p-4">
                <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-opacity=".3" d="M18.5 10.2c0 2.57-2.1 5.79-6.16 9.51l-.34.3-.34-.31C7.6 15.99 5.5 12.77 5.5 10.2c0-3.84 2.82-6.7 6.5-6.7s6.5 2.85 6.5 6.7z"></path><path d="M12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2zm6 8.2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                </svg>
              </div>
              <div class="flex w-full flex-wrap content-center">
                <span class="w-full">{{ address.nickname }}</span>
                <p class="w-full">{{ address.street }}, {{ address.number }} {{ address.complement ? '(' + address.complement + ')' : '' }} - {{ address.neighborhood.name }}</p>
                <p class="w-full">{{ address.city.name }} {{ address.city.state.code ? ' / ' + address.city.state.code : '' }}</p>
              </div>
            </div>
          </div>
          <button
            @click="this.$router.push('customer')"
            class="w-full bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
          >
            Novo endereço
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useUserStore } from "~/stores/userStore"

export default {
  data() {
    return {
      is_open: false,
      addresses: useUserStore().getUser.addresses
    };
  },
  methods: {
    showModal() {
      this.is_open = true;
    },
    handleAddressToDelivery(index) {
      this.$emit('input-address', this.addresses[index]);
      this.is_open = false;
    }
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
