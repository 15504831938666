<template>
  <div class="bg-[#f4f3ef] flex justify-center w-full h-full">
    <div class="w-full" v-if="cart_products.length">
      <div
        v-for="(product, index) in cart_products" :key="index"
        class="w-full product-section"
      >
        <a class="w-full text-left flex flex-wrap gap-2 items-center py-4 px-6 transition-all hover:bg-[#fcfcfc]">
          <div class="flex w-full">
            <div class="flex items-center w-4/5 gap-3">
              <img class="rounded w-10 h-10 object-cover" :src="`https://monteseubolo.com/admin/storage/products_images/${product.id}/${product.cover_image}`">
              <span class="ml-[-1.2rem] bg-[#f57f7f] p-1 rounded-full w-6 h-6 flex justify-center items-center text-xs">
                {{ product.quantity ?? 1 }}
              </span>
              <div class="3/4">
                <p class="w-full text-black">{{ product.title }} ({{ product.category.title }})</p>
                <p class="w-full">R$ {{ parseFloat(product.price).toFixed(2) }}</p>
              </div>
            </div>
            <div class="w-1/5 flex justify-end">
              <div class="flex gap-4 content-center justify-center items-center">
                <span
                  @click="increaseQuantity(index)"
                  class="w-1/2 transition-all cursor-pointer text-2xl text-[#a1a2a6] block uppercase hover:text-[#f57f7f]"
                >+1</span>
                <svg
                  @click="$refs.ModalConfirmDeleteProductInCart.showModal(index)"
                  class="w-1/2 max-w-10 cursor-pointer transition-all fill-[#f57f7f] hover:fill-[#82afd87f]"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="w-10/12 flex flex-wrap content-center gap-2">
            <variables-item
              v-for="(item, index) in product.optionals" :key="item.id"
              :items="item"
              :name="index"
            ></variables-item>
            <div v-if="product?.observations" class="w-full flex flex-wrap gap-1">
              <p class="w-full">Observação:</p>
              <p class="w-full pl-4">
                {{ product.observations }}
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="flex justify-center mt-4">
        <a
          @click="this.$router.push('/')"
          class="flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white uppercase"
        > 
          <svg class="fill-[#fff] w-6 hover:fill-[#82afd87f] transition-all" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-9.83-3.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4h-.01l-1.1 2-2.76 5H8.53l-.13-.27L6.16 6l-.95-2-.94-2H1v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.13 0-.25-.11-.25-.25z"></path>
          </svg>
          Comprar Mais
        </a>
      </div>
    </div>
    <div v-else class="bg-[#f4f3ef] flex justify-center w-full h-full">
      <no-products-selected></no-products-selected>
    </div>
    <div
      v-if="cart_products.length"
      class="fixed w-full bottom-16 bg-[#f57f7f] bg-opacity-50 flex justify-between items-center flex-wrap p-4"
    >
      <span>
        Total: R$ {{ parseFloat(cartTotalPrice ?? 0).toFixed(2) }}
      </span>
      <div class="flex justify-center">
        <a
          @click="this.$router.push('confirmation')"
          class="flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white uppercase"
        > 
          Finalizar
          <svg class="fill-[#fff] w-6" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
  <modal-confirm-delete-product-in-cart ref="ModalConfirmDeleteProductInCart"></modal-confirm-delete-product-in-cart>
</template>
<script>
import { useShopCartStore } from "~/stores/shopCartStore"
import ModalConfirmDeleteProductInCart from './ModalConfirmDeleteProductInCart.vue';
import VariablesItem from './VariablesItem.vue';
import NoProductsSelected from '@components/NoProductsSelected.vue';
import CartTotalPrice from "~/mixins/cart-total-price";

export default {
  name: 'CartIndex',
  components: {
    ModalConfirmDeleteProductInCart,
    VariablesItem,
    NoProductsSelected
  },
  mixins: [CartTotalPrice],
  data() {
    return {
      cart_products: []
    }
  },
  created() {
    this.cart_products = useShopCartStore().getShopCart;
  },
  methods: {
    increaseQuantity(index) {
      if(!this.cart_products[index].quantity) {
        this.cart_products[index].quantity = 2;
      } else {
        this.cart_products[index].quantity++;
      }
    },
  },
  watch: {
    cart_products: {
      handler: function(value) {
        useShopCartStore().setShopCart(value);
      },
      deep: true
    },
  }
}
</script>