<template>
  <div>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 w-screen flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-[85vw] flex flex-wrap max-w-screen-xl p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white gap-4">
          <div class="w-full flex flex-wrap px-4 pt-4 justify-between">
            <a class="font-bold transition-all tracking-wider block uppercase text-[#f57f7f] pointer-events-none">Pedido</a>
            <button
              @click="handleBuyAgain"
              class="bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
            >
              Pedir novamente
            </button>
          </div>
          <div class="w-full flex flex-wrap px-4">
            <p>Dados do pedido</p>
            <div class="w-full flex content-center">
              <div class="w-full flex flex-wrap content-center p-4 gap-2">
                <span class="w-full flex items-center gap-2">
                  <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M5 8h14V6H5z" opacity=".3"></path><path d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path>
                  </svg>
                  <div class="flex flex-col text-left">
                    <p>Pedido em</p>
                    {{ moment(sale_order.created_at).format('LLL') }}
                  </div>
                </span>
                <span class="w-full flex items-center gap-2">
                  <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M5 8h14V6H5z" opacity=".3"></path><path d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path>
                  </svg>
                  <div class="flex flex-col text-left">
                    <p>Previsão</p>
                    {{ moment(sale_order.schedules.end).format('LLL') }}
                  </div>
                </span>
                <p class="w-full flex items-center gap-2">
                  <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
                  </svg>
                  R$ {{ sale_order.total }}
                </p>
              </div>
              <div class="w-full flex flex-wrap content-center p-4 pb-0 pr-8">
                <span class="w-full flex items-center justify-end gap-2">
                  <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M16.49 13.75L14 16.25l2.49 2.5V17H22v-1.5h-5.51zm3.02 6H14v1.5h5.51V23L22 20.5 19.51 18zM7.5 3.5c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm2.05 6.9C10.85 12 12.8 13 15 13v-2c-1.85 0-3.45-1-4.35-2.45l-.95-1.6C9.35 6.35 8.7 6 8 6c-.25 0-.5.05-.75.15L2 8.3V13h2V9.65l1.75-.75L3 23h2.1l1.75-8L9 17v6h2v-7.55L8.95 13.4l.6-3z"></path>
                  </svg>
                  {{ sale_order.street ? 'Entrega' : 'Retirada' }}
                </span>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-wrap px-4 pb-4">
            <p>Produtos do pedido</p>
            <div class="w-full flex flex-wrap content-center">
              <div
                v-for="sale_order_product in sale_order.products" :key="sale_order_product.id"
                class="w-full flex flex-wrap content-center p-4 pb-0"
              >
                <span class="w-full flex items-center gap-2">
                  {{ sale_order_product.title }} ({{ sale_order_product.quantity }}x)
                </span>
                <p class="w-full flex items-center gap-2">
                  {{ handleTextOptionals(sale_order_product.optionals) }}
                </p>
              </div>
            </div>
          </div>
          <button
            v-if="!sale_order.payments.date_approved"
            @click="confirmPayment"
            class="w-full flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white rounded-md"
          > 
            Confirmar Pagamento
            <svg class="fill-[#fff] w-6" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
            </svg>
          </button>
          <button
            @click="onToggle"
            class="w-full bg-[#82afd87f] hover:bg-[#82afd87f] border-[#82afd87f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
          >
            Fechar
          </button>
        </div>
      </div>
    </transition>
    <modal-simple-text
      ref="ModalSimpleText"
      :show_footer="false"
    ></modal-simple-text>
  </div>
</template>

<script>
import { useShopCartStore } from "~/stores/shopCartStore"
import axios from 'axios';
import ModalSimpleText from '@components/ModalSimpleText.vue';
import moment from 'moment'
moment.locale('pt-br');

export default {
  data() {
    return {
      moment: moment,
      is_open: false,
      sale_order: null
    };
  },
  components: {
    ModalSimpleText
  },
  methods: {
    onToggle() {
      this.is_open = !this.is_open;
    },
    showModal(sale_order) {
      this.sale_order = sale_order;
      this.is_open = true;
    },
    handleTextOptionals(optionals) {
      let arr_optionals = [];
      Object.keys(optionals).forEach(optional_category => {
        optionals[optional_category].forEach(optional => {
          arr_optionals.push(optional.title + (optional.quantity > 1 ? ` (${optional.quantity}x)` : ''));
        });
      });
      return arr_optionals.join(', ');
    },
    handleBuyAgain() {
      let cart_products = [];
      this.sale_order.products.forEach(product => {
        cart_products.push({
          id: product.id,
          title: product.title,
          description: product.description,
          price: product.price,
          cover_image: product.cover_image,
          category: product.category,
          minutes_to_produce: product.minutes_to_produce,
          optionals: product.optionals,
          quantity: product.quantity,
          observations: product.observations
        });
      });
      useShopCartStore().setShopCart(cart_products);
      this.$router.push({ name: 'CartView' })
    },
    async confirmPayment() {
      await axios.get("/api/checkout/get-by-sale-order-id/" + this.sale_order.id)
        .then(({data}) => {
          if(data.date_approved) {
            this.$refs.ModalSimpleText.title = 'Sucesso';
            this.$refs.ModalSimpleText.message = 'Seu pedido foi agendado com sucesso!';
            this.$refs.ModalSimpleText.showModal()
            setTimeout(() => {
              this.$router.push({ name: 'HomeView' })
            }, (3 * 1000));
          } else {
            alert('Pagamento não registrado em nosso sistema.');
          }
        });
    }
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
