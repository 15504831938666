<template>
  <div class="w-full flex flex-col items-center transition-all">
    <div class="w-full flex flex-wrap">
      <div
        @click="$refs.ModalPayment.showModal()"
        class="w-full text-left h-28 flex items-center py-4 transition-all hover:bg-[#fcfcfc] cursor-pointer"
      >
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"></path>
          </svg>
        </div>
        <div class="w-full flex flex-wrap content-center">
          <p class="w-full text-black">Pagamento</p>
          <div v-if="!payment">
            <p class="w-full">Tipo de pagamento não selecionado</p>
          </div>
          <div v-else>
            <p>{{ payment_methods_translation[payment.type] }}</p>
          </div>
        </div>
        <p v-if="!payment" class="flex flex-wrap content-center justify-center p-4">
          Selecionar
        </p>
      </div>
      <modal-payment
        :payment_methods="payment_methods"
        :payment_methods_translation="payment_methods_translation"
        @input-payment="(item) => handlePayment(item)"
        ref="ModalPayment"
      ></modal-payment>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ModalPayment from './ModalPayment.vue';
moment.locale('pt-br');

export default {
  name: 'SectionPayment',
  components: {
    ModalPayment
  },
  data() {
    return {
      moment: moment,
      payment_methods_translation: {
        pix: 'Pix',
        on_pickup: 'Dinheiro na entrega/retirada',
        credit_card: 'Cartão de Crédito',
        debit_card: 'Cartão de Débito'
      },
      payment_methods: [
        'pix',
        'credit_card',
        'on_pickup',
        // 'debit_card'
      ],
    }
  },
  props: {
    payment: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handlePayment(item) {
      this.$emit('input-payment', item);
    }
  },
}
</script>

<style>
</style>
