<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-signin/>
  </main>
</template>

<script>
import { useUserStore } from "~/stores/userStore"
import SectionSignin from '@layouts/section-signin/Index.vue';

export default {
  components: {
    SectionSignin,
  },
  mounted() {
    this.validateInitialTab();
  },
  methods: {
    validateInitialTab() {
      if(useUserStore().getUser?.id) {
        this.$router.push({ name: 'CustomerView' })
      }
    },
  }
}
</script>