import { defineStore } from "pinia";

export const useDeliveryNeighborhoodsStore = defineStore("delivery_neighborhoods", {
  state: () => {
    return {
      delivery_neighborhoods: []
     };
  },
  actions: {
    setDeliveryNeighborhoods(value) {
      this.delivery_neighborhoods = value
    },
    resetDeliveryNeighborhoods() {
      this.delivery_neighborhoods = [];
    }
  },
  getters: {
    getDeliveryNeighborhoods: (state) => {
      return state.delivery_neighborhoods;
    }
  },
});