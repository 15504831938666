<template>
  <div>
    <button @click="onToggle">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px" class="cursor-pointer transition-all fill-[#f57f7f] hover:fill-[#82afd87f]">
        <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"/>
      </svg>
    </button>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 w-screen h-screen flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-full max-w-lg p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
          <div>
            <div class="w-full h-full">
              <ul class="flex justify-between border-b gap-6 p-4">
                <li @click="show_tab = 'geral'" class="text-[#f57f7f] text-xs md:text-base mr-1 cursor-pointer">
                  <a :class="{ 'text-[#f57f7f] font-bold': show_tab === 'geral' }" class="transition-all tracking-wider text-[#a1a2a6] block uppercase hover:text-[#f57f7f]">Sobre</a>
                </li>
                <li @click="show_tab = 'time'" class="text-xs md:text-base mr-1 cursor-pointer">
                  <a :class="{ 'text-[#f57f7f] font-bold': show_tab === 'time' }" class="transition-all tracking-wider text-[#a1a2a6] block uppercase hover:text-[#f57f7f]">Horários</a>
                </li>
                <li @click="show_tab = 'addresses'" class="text-xs md:text-base mr-1 cursor-pointer">
                  <a :class="{ 'text-[#f57f7f] font-bold': show_tab === 'addresses' }" class="transition-all tracking-wider text-[#a1a2a6] block uppercase hover:text-[#f57f7f]">Entrega</a>
                </li>
                <li @click="show_tab = 'payment'" class="text-xs md:text-base mr-1 cursor-pointer">
                  <a :class="{ 'text-[#f57f7f] font-bold': show_tab === 'payment' }" class="transition-all tracking-wider text-[#a1a2a6] block uppercase hover:text-[#f57f7f]">Pagamento</a>
                </li>
              </ul>
            </div>
            <div class="w-full p-6 h-[50vh] overflow-y-auto text-left">
              <div
                v-if="show_tab === 'geral'"
                class="flex flex-wrap gap-4"
              >
                <p>Desde 2018 fazendo doces para deixar a sua vida mais feliz!</p>
                <p class="font-bold flex flex-col gap-2">
                  Nosso Instagram
                  <a href="https://www.instagram.com/monteseubolooficial/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="transition-all bg-[#fba1a1] rounded-full hover:rounded hover:bg-[#f97070] p-2" viewBox="0 0 50 50" width="50px" height="50px" fill="#FFF">
                      <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/>
                    </svg>
                  </a>
                </p>
              </div>
              <div v-if="show_tab === 'time'">
                <p class="mt-1" v-for="(weekday , index) in opening_hours" :key="weekday">
                  <span class="font-bold">{{ weekdays[index] }}</span>: {{ weekday.opening_time }} - {{ weekday.closing_time }}
                </p>
              </div>
              <div v-if="show_tab === 'addresses'">
                <p v-for="neighborhood in neighborhoods" :key="neighborhood.id">
                  {{ neighborhood.name }} - R$ {{ parseFloat(neighborhood.price).toFixed(2) }}
                </p>
              </div>
              <div v-if="show_tab === 'payment'">
                <p>Pix</p>
                <p>Cartão de Crédito</p>
                <p>Cartão de Débito</p>
              </div>
            </div>
            <div class="p-3 mt-2 text-center space-x-4 md:block">
              <button
                @click="onToggle"
                class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import { useDeliveryNeighborhoodsStore } from "~/stores/deliveryNeighborhoodsStore"
import { useCompanySettingsStore } from "~/stores/companySettingsStore"

export default {
  data() {
    return {
      is_open: false,
      show_tab: 'geral',
      opening_hours: [],
      weekdays: {
        "sunday": "Dom",
        "monday": "Seg",
        "tuesday": "Ter",
        "wednesday": "Qua",
        "thursday": "Qui",
        "friday": "Sex",
        "saturday": "Sáb"
      },
      neighborhoods: []
    };
  },
  mounted() {
    this.fetchCompanySettings();
    if(!useDeliveryNeighborhoodsStore().getDeliveryNeighborhoods.length) {
      this.getNeighborhoods();
    } else {
      this.neighborhoods = useDeliveryNeighborhoodsStore().getDeliveryNeighborhoods;
    }
  },
  methods: {
    onToggle() {
      this.is_open = !this.is_open;
    },
    async getNeighborhoods() {
      await axios.get("/api/delivery/get-neighborhoods")
      .then(response => {
        this.neighborhoods = response.data;
        useDeliveryNeighborhoodsStore().setDeliveryNeighborhoods(response.data);
      }).catch(error => {
        throw error;
      });
    },
    async fetchCompanySettings() {
      return await axios.get("/api/get-company-settings")
      .then(response => {
        useCompanySettingsStore().setCompanySettings(response.data)
        this.opening_hours = JSON.parse(response.data.opening_hours);
      }).catch(error => {
        throw error;
      });
    },
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
