<template>
  <div
    class="w-full flex flex-col items-center gap-4 transition-all max-h-fit"
    id="section-customer-telephone"
  >
    <logo-rounded :classes="'w-32 mt-[-6rem]'"></logo-rounded>
    <input
      ref="sectionCustomerTelephone"
      id="section-customer-telephone-input"
      class="border-none w-full rounded p-4"
      placeholder="Digite o seu celular"
      type="text"
      maxlength="25"
      v-model="data.telephone.telephone"
      @keyup="validateTelephone($event)"
    />
    <div v-if="telephone_exists" class="w-full flex flex-wrap gap-2">
      <input
        id="section-customer-password"
        ref="sectionCustomerPassword"
        class="border-none w-full rounded p-4"
        placeholder="Digite sua senha"
        type="password"
        maxlength="40"
        v-model="data.password"
      />
      <div class="flex w-full">
        <input
          @click="togglePassword(false)"
          id="section-customer-password-checkbox"
          ref="sectionCustomerPasswordCheckbox"
          type="checkbox"
          value=""
          class="w-4 h-4 text-[#f57f7f] bg-gray-100 border-gray-300 rounded"
        >
        <label
          @click="togglePassword(true)"
          for="checked-checkbox"
          class="ms-2 text-sm font-medium text-gray-400"
        >Mostrar senha</label>
      </div>
    </div>
    <button
      @click="handleSubmit"
      id="section-customer-telephone-button-send"
      :disabled="disabled"
      class="w-full disabled:bg-opacity-20 justify-center cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase"
    >
      Próximo
    </button>
  </div>
</template>

<script>
import LogoRounded from '@components/LogoRounded.vue';

export default {
  name: 'TelephoneIndex',
  data() {
    return {
      disabled: true,
      data: {
        telephone: {
          ddi: '',
          telephone: ''
        },
        password: ''
      }
    }
  },
  props: {
    telephone_exists: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LogoRounded,
  },
  mounted() {
    this.modifyTelephoneInputToCountries();
  },
  methods: {
    handleSubmit() {
      let customer_country_code = document.getElementsByClassName("iti__selected-dial-code")[0].innerHTML.replace(/\D/g, '');
      let customer_telephone = this.$refs.sectionCustomerTelephone.value.replace(/\D/g, '');
      this.data.telephone.ddi = customer_country_code;
      this.data.telephone.telephone = customer_telephone;

      if(this.telephone_exists) {
        this.$emit('handleLogin', this.data);
      } else {
        this.$emit('handleTelephone', this.data.telephone);
      }
    },
    modifyTelephoneInputToCountries() {
      window.intlTelInput(this.$refs.sectionCustomerTelephone, {
        separateDialCode: true,
        preferredCountries: [
          "br"
        ],
        initialCountry:"br",
        onlyCountries: [
          "ZZ",
          "AR",
          "AU",
          "BR",
          "CN",
          "DE",
          "MX",
          "PY",
          "PT",
          "GB",
          "US",
          "UY"
        ]
      });
    },
    validateTelephone(event) {
      let input = event.target;
      let country_code = document.getElementsByClassName("iti__selected-dial-code")[0].innerHTML;
      this.disabled = input.value.length > 10 ? false : true;
      input.value = this.phoneMask(input.value, country_code)
    },
    phoneMask(value, country_code) {
      if (!value) {
        return null;
      } else {
        value = value.replace(/\D/g, '');
        if(country_code === "+55") {
          value = value.replace(/\D/g,'')
            .replace(/(\d{2})(\d)/,"($1) $2")
            .replace(/(\d)(\d{4})$/,"$1-$2");
        } else if(country_code === "+1") {
          value = value.replace((/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), "($1) $2-$3");
        }
        return value;
      }
    },
    togglePassword(check) {
      if(check) {
        this.$refs.sectionCustomerPasswordCheckbox.checked = !this.$refs.sectionCustomerPasswordCheckbox.checked;
      }
      if (this.$refs.sectionCustomerPassword.type === "password") {
        this.$refs.sectionCustomerPassword.type = "text";
      } else {
        this.$refs.sectionCustomerPassword.type = "password";
      }
    },
  }
}
</script>

<style>
</style>
