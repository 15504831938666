<template>
  <div
    class="flex flex-wrap mt-6"
    id="section-product-optional-inside"
  >
    <label for="section-product-optional-inside" class="w-full flex items-center justify-between text-sm font-medium">
      {{ name }}
      <span
        id="section-product-optional-inside-limit"
        class="cursor-default max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white"
      >Até {{ limit }} {{ limit > 1 ? 'itens' : 'item' }}</span>
    </label>
    <div class="w-full" id="section-product-optional-inside-container">
      <a
        v-for="(item, index) in items" :key="item.id"
        @click="item.type.selection_type === 'unique' ? manipulateCheck(index) : ''"
        class="home-content-item w-full text-left h-28 flex items-center py-4 px-4 transition-all hover:bg-[#fcfcfc]"
      >
        <div class="w-full flex flex-wrap content-center">
          <p class="w-full text-black">{{ item.title }}</p>
          <p class="w-full">{{ item.description }}</p>
          <p v-if="item.price" class="w-full text-red-500">+ R$ {{ parseFloat(item.price).toFixed(2) }}</p>
        </div>
        <div v-if="item.type.selection_type === 'quantity'" class="flex items-center gap-2 w-auto">
          <svg @click="manipulateQuantities(index, 'minus')" xmlns="http://www.w3.org/2000/svg" class="item-minus transition-all bg-[#9e9e9e] rounded-full hover:rounded hover:bg-[#f97070] p-2" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <path d="M 21 11.75 C 21 11.335938 20.664062 11 20.25 11 L 3.75 11 C 3.335938 11 3 11.335938 3 11.75 C 3 12.164062 3.335938 12.5 3.75 12.5 L 20.25 12.5 C 20.664062 12.5 21 12.164062 21 11.75 Z M 21 11.75 "/>
          </svg>
          <span class="item-quantity pointer-events-none">{{ items[index]?.quantity ?? 0 }}</span>
          <svg @click="manipulateQuantities(index, 'plus')" xmlns="http://www.w3.org/2000/svg" class="item-plus transition-all bg-[#fba1a1] rounded-full hover:rounded hover:bg-[#f97070] p-2" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <path d="M 24 10 L 14 10 L 14 0 L 10 0 L 10 10 L 0 10 L 0 14 L 10 14 L 10 24 L 14 24 L 14 14 L 24 14 Z M 24 10 "/>
          </svg>
        </div>
        <div v-else class="flex gap-2 w-auto">
          <input type="radio" :checked="items[index].checked">
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpcionalIndex',
  data() {
    return {
      items: this.opcionals_items,
      total_items_selected: 0
    }
  },
  props: {
    opcionals_items: {
      type: Object,
      default: () => {},
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    manipulateCheck(index) {
      for(var key in this.items) {
        this.items[key].checked = false;
      }

      this.items[index].checked = true;
    },
    manipulateQuantities(index, action) {
      if(!this.items[index]?.quantity) {
        this.items[index].quantity = 0;
      }

      if (action === "minus") {
        if (this.items[index].quantity > 0) {
          this.items[index].quantity--;
          this.total_items_selected--;
        }
      } else if (action === "plus") {
        if(this.total_items_selected < this.limit) {
          this.items[index].quantity++;
          this.total_items_selected++;
        }
      }
    },
  },
  watch: {
    items: {
      handler: function(value) {
        this.$emit('change-optional', value.filter((item) => item.quantity > 0 || item.checked));
      },
      deep: true
    },
  }
}
</script>