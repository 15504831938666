<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-home/>
  </main>
</template>

<script>
import SectionHome from '@layouts/section-home/Index.vue';

export default {
  components: {
    SectionHome
  }
}
</script>