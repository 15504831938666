<template>
  <div class="w-full flex flex-col items-center transition-all">
    <div class="w-full flex flex-wrap">
      <div
        @click="handleAddress"
        class="w-full text-left h-28 flex items-center py-4 transition-all hover:bg-[#fcfcfc] cursor-pointer"
      >
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-opacity=".3" d="M18.5 10.2c0 2.57-2.1 5.79-6.16 9.51l-.34.3-.34-.31C7.6 15.99 5.5 12.77 5.5 10.2c0-3.84 2.82-6.7 6.5-6.7s6.5 2.85 6.5 6.7z"></path><path d="M12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2zm6 8.2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
          </svg>
        </div>
        <div class="w-full flex flex-wrap content-center">
          <p class="w-full text-black">Vou retirar no local</p>
          <div>
            <p>Mercado Irineu</p>
            <p>R. Gaspar Silveira Martins, 1089 - Centro</p>
            <p>Santa Cruz do Sul / RS</p>
          </div>
        </div>
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionWithdrawal',
  methods: {
    handleAddress() {
      window.open('https://www.google.com/maps/dir//1089,+R.+Gaspar+Silveira+Martins,+1005+-+Margarida,+Santa+Cruz+do+Sul+-+RS/@-29.7053132,-52.5065136,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x951ca33639d38941:0x88edbb10805d864e!2m2!1d-52.4241497!2d-29.7053457?entry=ttu', '_blank');
    }
  },
}
</script>

<style>
</style>
