<template>
  <div class="w-full flex flex-col items-center transition-all">
    <div class="w-full flex flex-wrap">
      <div
        @click="$refs.ModalSchedule.showModal()"
        class="w-full text-left h-28 flex items-center py-4 transition-all hover:bg-[#fcfcfc] cursor-pointer"
      >
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M12 6c-3.87 0-7 3.13-7 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7zm3.75 10.85L11 14V8h1.5v5.25l4 2.37-.75 1.23z" opacity=".3"></path><path d="M12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm.5-12H11v6l4.75 2.85.75-1.23-4-2.37zM22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53z"></path>
          </svg>
        </div>
        <div class="w-full flex flex-wrap content-center">
          <p class="w-full text-black">Agendamento</p>
          <div v-if="!schedule">
            <p class="w-full">Pedido ainda não agendado</p>
          </div>
          <div v-else>
            <p>{{ moment(schedule).format('LLL') }}</p>
          </div>
        </div>
        <p v-if="!schedule" class="flex flex-wrap content-center justify-center p-4">
          Agendar
        </p>
      </div>
      <modal-schedule
        @input-schedule="(item) => handleSchedule(item)"
        :cart="cart"
        ref="ModalSchedule"
      ></modal-schedule>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ModalSchedule from './ModalSchedule.vue';
moment.locale('pt-br');

export default {
  name: 'SectionSchedule',
  components: {
    ModalSchedule
  },
  data() {
    return {
      moment: moment
    }
  },
  props: {
    schedule: {
      type: Date
    },
    cart: {
      type: Array
    }
  },
  methods: {
    handleSchedule(item) {      
      this.$emit('input-schedule', item);
    }
  },
}
</script>

<style>
</style>
