import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: {}
     };
  },
  actions: {
    setUser(value) {
      this.user = {
        id: value.id ?? null,
        first_name: value.first_name ?? null,
        last_name: value.last_name ?? null,
        email: value.email ?? null,
        date_of_birth: value.date_of_birth ?? null,
        ddi: value.ddi ?? '',
        telephone: value.telephone ?? '',
        tax_id: value.tax_id ?? '',
        addresses: value.addresses ?? []
      }
    },
    resetUser() {
      this.user = {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        date_of_birth: null,
        ddi: '',
        telephone: '',
        addresses: []
      };
    }
  },
  getters: {
    getUser: (state) => {
      return state.user;
    }
  },
});