<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-payments-pix
      v-if="payment.payment_type === 'pix'"
      :payment="payment"
    />
    <section-payments-credit-debit-card
      v-if="['credit_card', 'debit_card'].includes(payment.payment_type)"
      :payment="payment"
    />
  </main>
</template>

<script>
import { useUserStore } from "~/stores/userStore"
import { usePaymentStore } from "~/stores/paymentStore"
import SectionPaymentsPix from '@layouts/section-payments/pix.vue';
import SectionPaymentsCreditDebitCard from '@layouts/section-payments/credit-debit-card.vue';

export default {
  components: {
    SectionPaymentsPix,
    SectionPaymentsCreditDebitCard
  },
  data() {
    return {
      loading: true,
      payment: []
    }
  },
  mounted() {
    if(!useUserStore().getUser?.id) {
      this.$router.push({ name: 'SigninView' })
    } else if(!usePaymentStore().getPayment?.value || !usePaymentStore().getPayment?.payment_type) {
      this.$router.push({ name: 'OrdersView' })
    } else if(usePaymentStore().getPayment?.payment_type === 'on_pickup') {
      this.$router.push({ name: 'OrdersView' })
    } else {
      this.payment = usePaymentStore().getPayment;
    }
  },
}
</script>