<template>
  <div>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-full max-w-lg p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
          <div>
            <div class="w-full flex flex-wrap p-6 overflow-y-auto text-left gap-4">
              <div class="w-full h-full">
                <a class="text-[#f57f7f] text-xs md:text-base transition-all tracking-wider block uppercase hover:text-[#f57f7f] pointer-events-none">Excluir</a>
              </div>
              <div>
                <p v-if="product?.quantity && product.quantity > 1">Existem mais de um produto nesta seleção. Quantos você deseja remover?</p>
                <p v-else>Você confirma a exclusão?</p>
              </div>
            </div>
            <div class="flex w-full justify-between p-3">
              <button
                @click="this.is_open = !this.is_open"
                class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Cancelar
              </button>
              <div class="flex gap-4" v-if="product?.quantity && product.quantity > 1">
                <button
                  @click="deleteProductInCart(1)"
                  class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
                >
                  Só um
                </button>
                <button
                  @click="deleteProductInCart()"
                  class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
                >
                  Todos
                </button>
              </div>
              <div v-else>
                <button
                  @click="deleteProductInCart()"
                  class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
                >
                  Sim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_open: false,
      product_index: null,
      product: {}
    };
  },
  methods: {
    showModal(index) {
      this.product = this.$parent.cart_products[index];
      this.product_index = index;
      this.is_open = true;
    },
    deleteProductInCart(quantity = null) {
      if(quantity) {
        this.$parent.cart_products[this.product_index].quantity--;
      } else {
        this.$parent.cart_products.splice(this.product_index, 1); 
      }
      this.is_open = false;
    }
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
