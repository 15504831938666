<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-product>
    </section-product>
  </main>
</template>

<script>
import SectionProduct from '@layouts/section-product/Index.vue';

export default {
  components: {
    SectionProduct
  },
}
</script>