<template>
  <div
    class="w-full flex flex-col items-center gap-4 transition-all"
    id="section-customer-create-address"
  >
    <div class="w-full flex flex-wrap justify-start gap-4">
        <div class="w-full flex flex-wrap">
          <input
            class="border-none w-full rounded p-4"
            placeholder="CEP"
            type="text"
            id="section-customer-create-address-zip-code"
            @keyup="fetchCep"
            v-model="address.zip_code"
          />
        </div>
        <div class="w-full flex flex-wrap">
          <input
            class="border-none w-full rounded p-4"
            placeholder="Cidade"
            readonly
            type="text"
            :disabled="disabled_inputs"
            id="section-customer-create-address-city"
            v-model="address.city.name"
          />
        </div>
        <div class="w-full flex flex-wrap">
          <select
            class="border-none w-full rounded p-4"
            id="section-customer-create-address-neighborhood"
            :disabled="disabled_inputs"
            v-model="address.neighborhood"
          >
            <option value="" disabled selected>Bairro</option>
            <option v-for="(item, index) in neighborhoods" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="w-full flex flex-wrap">
          <input
            class="border-none w-full rounded p-4"
            type="text"
            :disabled="disabled_inputs"
            placeholder="Rua"
            maxlength="255"
            id="section-customer-create-address-street"
            v-model="address.street"
          />
        </div>
        <div class="w-full flex flex-wrap">
          <input
            class="border-none w-full rounded p-4"
            type="text"
            :disabled="disabled_inputs"
            placeholder="Número"
            maxlength="255"
            id="section-customer-create-address-number"
            v-model="address.number"
          />
        </div>
        <div class="w-full flex flex-wrap">
          <input
            class="border-none w-full rounded p-4"
            type="text"
            :disabled="disabled_inputs"
            placeholder="Complemento"
            maxlength="255"
            id="section-customer-create-address-complement"
            v-model="address.complement"
          />
        </div>
        <div class="w-full flex flex-wrap">
          <select
            class="border-none w-full rounded p-4"
            id="section-customer-create-address-nickname"
            :disabled="disabled_inputs"
            v-model="address.nickname"
          >
            <option value="" disabled selected>Apelido do endereço *</option>
            <option>Casa</option>
            <option>Apartamento</option>
            <option>Trabalho</option>
          </select>
        </div>
    </div>
    
    <button
      @click="handleSubmit"
      id="section-customer-create-address-button"
      :disabled="!validateAddressInformations"
      class="w-full disabled:bg-opacity-20 p-4 justify-center cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase"
    >
      Cadastrar
    </button>
  </div>
</template>

<script>
import { useDeliveryNeighborhoodsStore } from "~/stores/deliveryNeighborhoodsStore"

export default {
  name: 'AddressCreate',
  data() {
    return {
      disabled_inputs: true,
      neighborhoods: [],
      address: {
        code: '',
        zip_code: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
        nickname: '',
        city: {
          id: null,
          name: '',
          state: {
            id: null,
            name: ''
          }
        }
      }
    }
  },
  mounted() {
    this.loadNeighborhoods();
  },
  methods: {
    handleSubmit() {
      this.$emit('input-address', this.address);
    },
    async loadNeighborhoods() {
      this.neighborhoods = useDeliveryNeighborhoodsStore().getDeliveryNeighborhoods;
    },
    fetchCep() {
      if(this.address.zip_code.length === 9) {
        fetch(`https://viacep.com.br/ws/${this.address.zip_code.replace(/\D/g, '')}/json/`)
          .then(response => response.json())
          .then(data => {
            if(data.localidade != 'Santa Cruz do Sul' || data.uf != 'RS') {
              alert('Infelizmente ainda não temos uma filial na sua cidade. Se você deseja abrir uma franquia entre em contato conosco!')
            } else {
              if(data.logradouro) {
                this.disabled_inputs = false;
                this.address.code = data.ibge;
                this.address.neighborhood = this.neighborhoods.find((neighborhood) => neighborhood.name === data.bairro)?.id ?? '';
                this.address.street = data.logradouro;
                this.address.complement = data.complemento;
                this.address.city.name = data.localidade;
                this.address.city.state.code = data.uf;
              }
            }
          });
      }
    }
  },
  computed: {
    validateAddressInformations() {
      if (
        this.address.zip_code.length === 9 &&
        this.address.neighborhood &&
        this.address.street.length > 3 &&
        this.address.number.length &&
        this.address.nickname.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    'address.zip_code': function(value) {
      if (value) {
        this.address.zip_code = value.replace(/\D/g,'').replace(/(\d{5})(\d)/,'$1-$2');
      }
    },
  }
}
</script>

<style>
</style>
