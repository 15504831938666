export default {
  methods: {
    handleDifferenceNowToDateInDays(date) {
      let date1 = new Date();
      let date2 = new Date(date);
      let difference_in_time = date2.getTime() - date1.getTime();
      return Math.round(difference_in_time / (1000 * 3600 * 24));
    },
  }
};
