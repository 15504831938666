<template>
  <div
    class="w-full flex flex-col items-center gap-6 transition-all"
    id="section-customer-user"
  >
    <div class="w-full flex flex-wrap">
      <span class="text-2xl uppercase w-full text-left">Meus dados</span>
      <div class="w-full text-left mt-2">
        <span>Nome</span>
        <p>{{ customer.first_name }} {{ customer.last_name }}</p>
      </div>
      <div class="w-full text-left mt-2">
        <span>Data de Nascimento</span>
        <p>{{ formatDate(customer.date_of_birth) }}</p>
      </div>
      <div class="w-full text-left mt-2">
        <span>Fone</span>
        <p>{{ customer.ddi }} {{ customer.telephone }}</p>
      </div>
    </div>
    <button
      @click="handleRoutes('orders')"
      class="w-full justify-center p-4 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white uppercase flex items-center"
    >
      <svg class="fill-[#ffffff] w-6" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"></path>
      </svg>
      <span class="ml-1">Meus Pedidos</span>
    </button>
    <div class="w-full flex flex-wrap">
      <span class="text-2xl uppercase w-full text-left">Endereços</span>
      <div
        v-if="customer.addresses.length"
        class="w-full flex flex-wrap gap-2 text-left mt-2"
      >
        <div v-for="(address, index) in customer.addresses" :key="index" class="w-full">
          <span>{{ address.nickname }}</span>
          <p>{{ address.street }}, {{ address.number }} {{ address.complement ? '(' + address.complement + ')' : '' }} - {{ address.neighborhood.name }}</p>
          <p>{{ address.city.name }} {{ address.city.state.code ? ' / ' + address.city.state.code : '' }}</p>
        </div>
      </div>
      <div v-else>
        <p>Sem endereços cadastrados</p>
      </div>
    </div>
    
    <button
      @click="this.$parent.active_tab = 'address-create'"
      id="section-customer-user-button-create-address"
      class="w-full justify-center p-4 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase"
    >
      Novo Endereço
    </button>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SectionShowUser',
  data() {
    return {
      disabled: true
    }
  },
  props: {
    customer: {
      type: Object,
      default: () => {}
    }
  },
  methods: { 
    formatDate(value){
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
      }
    },
    handleRoutes(route) {
      this.$router.push(route);
    }
   },
}
</script>

<style>
</style>
