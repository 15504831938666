import moment from 'moment'

export default {
  methods: {
    handleDifferenceNowToMinimumMinute(minimun_minute_in_same_day) {
      let date_to_compare = moment(new Date()).add(minimun_minute_in_same_day, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      date_to_compare = new Date(date_to_compare);
      let date_now = new Date();
      return date_to_compare.getTime() < date_now.getTime();
    },
  }
};
