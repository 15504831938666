<template>
  <div class="w-full flex flex-col items-center transition-all">
    <div class="w-full flex flex-wrap">
      <div
        @click="$refs.ModalConfirmCoupon.showModal()"
        class="w-full text-left h-28 flex items-center py-4 transition-all hover:bg-[#fcfcfc] cursor-pointer"
      >
        <div class="flex flex-wrap content-center justify-center p-4">
          <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M4.01 8.54C5.2 9.23 6 10.52 6 12s-.81 2.77-2 3.46V18h16v-2.54c-1.19-.69-2-1.99-2-3.46s.81-2.77 2-3.46V6H4l.01 2.54zM11 7h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" opacity=".3"></path><path d="M22 10V6c0-1.11-.9-2-2-2H4c-1.1 0-1.99.89-1.99 2v4c1.1 0 1.99.9 1.99 2s-.89 2-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2s.9-2 2-2zm-2-1.46c-1.19.69-2 1.99-2 3.46s.81 2.77 2 3.46V18H4v-2.54c1.19-.69 2-1.99 2-3.46 0-1.48-.8-2.77-1.99-3.46L4 6h16v2.54zM11 15h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z"></path>
          </svg>
        </div>
        <div class="w-full flex flex-wrap content-center">
          <p class="w-full text-black">Cupom</p>
          <div v-if="!coupon?.code">
            <p class="w-full">Nenhum cupom aplicado</p>
          </div>
          <div v-else-if="coupon.discount_type === 'percentage'">
            <p>{{ coupon.code }} - {{ coupon.discount_value }}%</p>
          </div>
          <div v-else>
            <p>{{ coupon.code }} - R$ {{ parseFloat(coupon.discount_value).toFixed(2) }}</p>
          </div>
        </div>
        <p class="flex flex-wrap content-center justify-center p-4">
          Aplicar
        </p>
      </div>
    </div>
    <modal-confirm-coupon
      @input-coupon="(item) => handleCoupon(item)"
      ref="ModalConfirmCoupon"
    ></modal-confirm-coupon>
  </div>
</template>

<script>
import ModalConfirmCoupon from './ModalConfirmCoupon.vue';

export default {
  name: 'SectionCoupon',
  components: {
    ModalConfirmCoupon
  },
  data() {
    return {
      coupon: {}
    }
  },
  methods: {
    handleCoupon(item) {
      this.coupon = item;
      this.$emit('input-coupon', this.coupon);
    }
  },
}
</script>

<style>
</style>
