<template>
  <div>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-full max-w-lg p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
          <div class="w-full flex flex-wrap pt-2 overflow-y-auto text-left gap-4">
            <div class="w-full flex justify-between items-center px-4">
              <a class="text-[#f57f7f] text-xs md:text-base transition-all tracking-wider uppercase hover:text-[#f57f7f] pointer-events-none">
                Escreva o código de cupom
              </a>
              <button
                @click="this.is_open = !this.is_open"
                class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Fechar
              </button>
            </div>
          </div>
          <div class="w-full flex flex-wrap pt-2 pb-4 text-left gap-4">
            <div class="w-full flex gap-4 text-[#f57f7f] text-xs md:text-base transition-all tracking-wider">
              <input
                v-model="coupon"
                placeholder="Digite o código de cupom"
                type="text"
                class="h-12 w-full border border-gray-300 rounded-lg block py-2.5 px-4 focus:outline-none"
                @input="(val) => (val.target.value = val.target.value.toUpperCase())"
              />
            </div>
          </div>
          <button
            @click="handleSubmit()"
            class="w-full bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
          >
            Salvar
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      is_open: false,
      coupon: null
    };
  },
  methods: {
    showModal() {
      this.is_open = true;
    },
    async handleSubmit() {
      await axios.get("/api/coupons/get-by-code/" + this.coupon)
      .then(({data}) => {
        if(data.message) {
          alert(data.message);
        } else {
          this.$emit('input-coupon', {
            id: data.id,
            code: this.coupon,
            discount_value: data.discount_value,
            discount_type: data.discount_type
          });
          this.is_open = false;
        }
      });
    }
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
