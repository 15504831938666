<template>
  <section class="bg-[#f4f3ef] flex justify-center">
    <div class="flex flex-wrap w-[85vw] md:w[50vw] justify-center content-center">
      <section-user-show
        :customer="customer"
        v-if="active_tab === 'user-show'"
      ></section-user-show>
      <section-address-create
        @input-address="(items) => handleCreateAddress(items)"
        v-if="active_tab === 'address-create'"
      ></section-address-create>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { useUserStore } from "~/stores/userStore"
import SectionAddressCreate from './address/Create.vue';
import SectionUserShow from './user/Show.vue';

export default {
  name: 'CustomerIndex',
  components: {
    SectionAddressCreate,
    SectionUserShow,
  },
  mounted() {
    this.customer = useUserStore().getUser;
  },
  data() {
    return {
      active_tab: 'user-show',
      customer: {
        id: null,
        first_name: null,
        last_name: null,
        date_of_birth: null,
        ddi: '',
        telephone: '',
        addresses: []
      }
    }
  },
  methods: {
    async handleCreateAddress(items) {
      await axios.post("/api/user/create-address", {
        user_id: this.customer.id,
        ...items,
      })
      .then(response => {
        this.customer.addresses.push(response.data)
        this.active_tab = 'user-show';
      });
    }
  },
  watch: {
    customer: {
      handler: function() {
        useUserStore().setUser(this.customer);
      },
      deep: true
    },
  }
}
</script>

<style>
</style>
