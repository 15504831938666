<template>
  <div>
    <button @click="onToggle">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px" class="cursor-pointer transition-all fill-[#f57f7f] hover:fill-[#82afd87f]">
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
      </svg>
    </button>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 w-screen flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-full flex flex-wrap max-w-lg p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white gap-4">
          <button
            @click="handleRoutes('orders')"
            class="cursor-pointer flex w-full items-center p-4 hover:bg-[#fcfcfc]"
          >
            <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"></path>
            </svg>
            <span class="ml-1">Meus Pedidos</span>
          </button>
          <button
            @click="onToggle"
            class="w-full bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
          >
            Fechar
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_open: false
    };
  },
  methods: {
    onToggle() {
      this.is_open = !this.is_open;
    },
    handleRoutes(route) {
      this.is_open = false;
      this.$router.push(route);
    }
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
