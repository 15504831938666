<template>
  <section class="bg-[#f4f3ef] flex justify-center">
    <div class="flex flex-wrap w-[85vw] md:w[50vw] justify-center content-center">
      <section-telephone-index
        @handleTelephone="(item) => handleTelephone(item)"
        @handleLogin="(item) => handleLogin(item)"
        :telephone_exists="telephone_exists"
        v-if="active_tab === 'telephone-index'"
      ></section-telephone-index>
      <section-user-create
        @handleUser="(items) => handleCreateUserData(items)"
        v-if="active_tab === 'user-create'"
      ></section-user-create>
    </div>
  </section>
</template>

<script>
import { useUserStore } from "~/stores/userStore"
import axios from 'axios';
import SectionUserCreate from './user/Create.vue';
import SectionTelephoneIndex from './telephone/Index.vue';

export default {
  name: 'CustomerIndex',
  components: {
    SectionUserCreate,
    SectionTelephoneIndex
  },
  data() {
    return {
      active_tab: 'telephone-index',
      customer: {
        id: null,
        first_name: null,
        last_name: null,
        date_of_birth: null,
        ddi: '',
        telephone: '',
        addresses: []
      },
      telephone_exists: false
    }
  },
  methods: {
    async handleTelephone(item) {
      await axios.post("/api/user/get-by-telephone", {
        ddi: item.ddi,
        telephone: item.telephone
      })
      .then(response => {
        if(response.data.id) {
          this.telephone_exists = true;
        } else {
          this.customer.ddi = item.ddi;
          this.customer.telephone = item.telephone;
          this.active_tab = 'user-create';
        }
      });
    },
    async handleCreateUserData(item) {
      await axios.put("/api/user/create-by-telephone", {
        ddi: this.customer.ddi,
        telephone: this.customer.telephone,
        first_name: item.first_name,
        last_name: item.last_name,
        date_of_birth: item.date_of_birth,
        password: item.password
      })
      .then(response => {
        useUserStore().setUser(response.data)
        this.$router.push({ name: 'CustomerView' })
      });
    },
    async handleLogin(item) {
      await axios.post("/api/user/login-by-telephone", {
        ddi: item.telephone.ddi,
        telephone: item.telephone.telephone,
        password: item.password
      })
      .then(response => {
        if(response.data?.id) {
          useUserStore().setUser(response.data)
          if(this.$router.options.history.state.back === '/confirmation') {
            this.$router.push({ name: 'ConfirmationView' })
          } else {
            this.$router.push({ name: 'CustomerView' })
          }
        } else {
          alert('Senha não coincide com o cadastro');
        }
      });
    }
  },
}
</script>

<style>
</style>
