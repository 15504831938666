<template>
  <div
    v-if="cart_products.length"
    class="fixed w-full bottom-16 bg-[#f57f7f] bg-opacity-60 flex justify-between items-center flex-wrap p-4 gap-8 h-50"
  >
    <div class="flex flex-wrap w-full gap-2">
      <div class="w-full flex justify-between">
        <span class="w-full flex">
          Subtotal ({{ totalProducts }} produto{{ totalProducts > 1 ? 's' : '' }})
        </span>
        <span class="w-full flex justify-end">
          R$ {{ parseFloat(cartTotalPrice ?? 0).toFixed(2) }}
        </span>
      </div>
      <div v-if="address?.id" class="w-full flex justify-between">
        <span class="w-full flex">
          Entrega
        </span>
        <span class="w-full flex justify-end">
          R$ {{ parseFloat(address.neighborhood.price).toFixed(2) }}
        </span>
      </div>
      <div v-if="coupon?.discount_value" class="w-full flex justify-between">
        <span class="w-full flex">
          Desconto
        </span>
        <span class="w-full flex justify-end">
          R$ {{ parseFloat(discountValue).toFixed(2) }}
        </span>
      </div>
      <div class="w-full flex justify-between text-lg">
        <span class="w-full flex">
          Total
        </span>
        <span class="w-full flex justify-end">
          R$ {{ fullPrice }}
        </span>
      </div>
    </div>
    <div class="flex justify-center w-full">
      <button
        @click="handleSubmit"
        :disabled="!validateOpenAndCloseHours ? true : disabledSubmit"
        class="w-full disabled:bg-opacity-25 flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white uppercase"
      > 
        {{ validateOpenAndCloseHours ? handleButtonSumbmitText : 'Loja fechada' }}
        <svg class="fill-[#fff] w-6" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { useShopCartStore } from "~/stores/shopCartStore"
import moment from 'moment'
import { useCompanySettingsStore } from "~/stores/companySettingsStore"
import CartTotalPrice from "~/mixins/cart-total-price";

export default {
  name: 'SectionShowUser',
  mixins: [CartTotalPrice],
  data() {
    return {
      cart_products: useShopCartStore().getShopCart,
      company_settings: useCompanySettingsStore().getCompanySettings,
      weekdays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
    }
  },
  props: {
    active_tab: {
      type: String
    },
    address: {
      type: Object,
      default: () => {}
    },
    schedule: {
      type: Date,
      default: () => null
    },
    disabled_submit: {
      type: Boolean,
      default: () => false
    },
    payment: {
      type: Object,
      default: () => null
    },
    coupon: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    fullPrice() {
      return ((parseFloat(this.cartTotalPrice ?? 0) + parseFloat(this.address?.neighborhood?.price ?? 0)).toFixed(2) - this.discountValue).toFixed(2);
    },
    discountValue() {
      if(this.coupon?.discount_value) {
        return this.coupon.discount_type === 'percentage' ? (parseFloat(this.cartTotalPrice ?? 0) * (this.coupon.discount_value / 100)) : (parseFloat(this.coupon.discount_value));
      } else {
        return 0;
      }
    },
    disabledSubmit() {
      return (this.active_tab === 'delivery' && !this.address?.id) || !this.schedule || this.disabled_submit || !this.payment ? true : false;
    },
    validateOpenAndCloseHours() {
      let opening_hours = JSON.parse(this.company_settings.opening_hours);
      let today_opening_hours = opening_hours[this.weekdays[new Date().getDay()]]

      return moment().isBetween(moment().format('YYYY-MM-DD') + ' ' + today_opening_hours.opening_time, moment().format('YYYY-MM-DD') + ' ' + today_opening_hours.closing_time, null, '[]');
    },
    handleButtonSumbmitText() {
      if(this.payment?.type === 'on_pickup') {
        return 'Finalizar';
      } else {
        return 'Ir para o pagamento';
      }
    },
    totalProducts() {
      return this.cart_products.reduce((total, item) => {
        return total + (item.quantity ?? 1);
      }, 0);
    }
  },
  watch: {
    cart_products: {
      handler: function(value) {
        this.cart_products = value;
      },
      deep: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('handle-submit', {
        sale_value: this.cartTotalPrice,
        shipping_value: this.address?.neighborhood?.price,
        discount: this.discountValue,
        total: this.fullPrice
      });
    },
  }
}
</script>

<style>
</style>
