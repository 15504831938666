import { defineStore } from "pinia";

export const usePaymentStore = defineStore("payment", {
  state: () => {
    return {
      payment: {}
     };
  },
  actions: {
    setPayment(value) {
      this.payment = value
    },
    resetPayment() {
      this.payment = {};
    }
  },
  getters: {
    getPayment: (state) => {
      return state.payment;
    }
  },
});