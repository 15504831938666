<template>
  <div class="w-full flex flex-wrap gap-1">
    <p class="w-full">{{ name }}:</p>
    <p v-for="(item, index) in items" :key="index" class="w-full pl-4 flex gap-2">
      {{ item.title }}
      <span v-if="item.price">(R$ {{ parseFloat(item.price).toFixed(2) }})</span>
      <span v-if="item?.quantity && item.quantity > 1" class="bg-[#f57f7f] text-white px-2 rounded-full flex justify-center items-center text-xs">
        {{ item.quantity }} selecionados
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      default: () => {},
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
}
</script>