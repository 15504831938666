<template>
  <footer class="absolute w-full bottom-0 bg-[#f57f7f] flex justify-center flex-wrap">
    <div class="w-full"> 
      <div class="w-full flex-wrap lg:flex-no-wrap justify-between grid grid-cols-3 gap-4 h-16">
        <a
          @click="this.$router.push('/')"
          class="flex justify-center cursor-pointer h-full"
          :class="{ 'border-solid border-[#82afd87f] border-b-4' : this.$route.name === 'HomeView' }"
        >
          <svg class="fill-[#fff] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"></path>
          </svg>
        </a>
        <a
          @click="this.$router.push('cart')"
          class="flex justify-center cursor-pointer"
          :class="{ 'border-solid border-[#82afd87f] border-b-4' : this.$route.name === 'CartView' }"
        >
          <svg class="fill-[#fff] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
          </svg>
          <span
            v-if="cart_products_lenght"
            class="absolute transform translate-x-4 translate-y-2 bg-[#82afd87f] bg-opacity-75 rounded-full w-5 h-5 flex justify-center items-center text-xs text-white"
          >
            {{ cart_products_lenght }}
          </span>
        </a>
        <a
          @click="this.$router.push('signin')"
          class="flex justify-center cursor-pointer"
          :class="{ 'border-solid border-[#82afd87f] border-b-4' : this.$route.name === 'SigninView' || this.$route.name === 'CustomerView' }"
        >
          <svg class="fill-[#fff] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
import { useShopCartStore } from "~/stores/shopCartStore"

export default {
  name: 'FooterIndex',
  data() {
    return {
      cart_products_lenght: 0,
      cart_products: useShopCartStore().getShopCart
    }
  },
  watch: {
    cart_products: {
      handler: function(value) {
        this.cart_products_lenght = value.reduce((total, item) => {
          return total + (item.quantity ?? 1);
        }, 0);
      },
      deep: true
    }
  },
};
</script>