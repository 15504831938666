<template>
  <div :style="{'background-image': `url(/assets/background-cake.png)`}">
    <div class="flex justify-center py-8">
      <h2 class="font-['Anjani'] text-white font-extralight text-[8vh]">Monte seu Bolo</h2>
    </div>
    <div class="flex items-end justify-center">
      <img
        class="w-16 lg:w-60 h-auto mb-[-3.2rem] lg:mb-[-2rem] rounded-xl"
        src="/assets/home-header/example-2-min.jpg"
      >
      <img
        class="w-32 lg:w-96 h-auto mb-[-4rem] mx-4 lg:mx-8 rounded-xl"
        src="/assets/home-header/example-1-min.jpg"
      >
      <img
        class="w-16 lg:w-60 h-auto mb-[-3.2rem] lg:mb-[-2rem] rounded-xl"
        src="/assets/home-header/example-3-min.jpg"
      >
    </div>
  </div>
</template>

<script>
export default {
}
</script>