<template>
  <div>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 flex justify-center items-center z-50 backdrop-blur-md">
        <div class="w-full md:w-[85vw] max-w-screen-xl p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white overflow-hidden">
          <div class="w-full flex flex-wrap pt-2 overflow-y-auto text-left gap-4">
            <div class="w-full flex justify-between items-center px-4">
              <a class="text-[#f57f7f] text-xs md:text-base transition-all tracking-wider uppercase hover:text-[#f57f7f] pointer-events-none">
                Pagamento
              </a>
              <button
                @click="this.is_open = !this.is_open"
                class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Fechar
              </button>
            </div>
          </div>
          <div class="w-full p-4">
            <div class="w-full flex flex-wrap">
              <select
                class="h-12 border border-gray-300 rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                v-model="payment.type"
              >
                <option value="" disabled selected>Meio de Pagamento *</option>
                <option v-for="(item, index) in payment_methods" :key="index" :value="item">{{ payment_methods_translation[item] }}</option>
              </select>
            </div>
          </div>
          <form v-if="['credit_card', 'debit_card'].includes(payment.type) && user.addresses.length" class="w-full flex flex-wrap p-4 pt-0 text-left gap-2">
            <div class="w-full flex flex-wrap text-left gap-2">
              <input
                v-model="payment.credit_debit_card.email"
                placeholder="Email"
                type="email"
                class="h-12 border border-gray-300 rounded-lg block w-full py-2.5 px-4 focus:outline-none"
              />
              <div class="w-full flex gap-2">
                <select
                  class="h-12 border border-gray-300 rounded-lg block w-1/3 py-2.5 px-4 focus:outline-none"
                  v-model="payment.credit_debit_card.identification_type"
                  placeholder="Tipo de Identificação"
                >
                  <option value="" disabled selected>Tipo de identificação *</option>
                  <option v-for="(item, index) in ['CPF', 'CNPJ']" :key="index" :value="item">{{ item }}</option>
                </select>
                <input
                  v-model="payment.credit_debit_card.tax_id"
                  placeholder="Número de Identificação"
                  v-mask="payment.credit_debit_card.identification_type === 'CNPJ' ? '##.###.###/####-##' : '###.###.###-##'"
                  type="text"
                  class="h-12 border border-gray-300 rounded-lg block w-2/3 py-2.5 px-4 focus:outline-none"
                />
              </div>
              <div class="w-full flex gap-2">
                <input
                  v-model="payment.credit_debit_card.card_holder_name"
                  placeholder="Nome do Titular do Cartão"
                  type="text"
                  class="h-12 border border-gray-300 rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                />
                <input
                  v-model="payment.credit_debit_card.expiration_date"
                  placeholder="MM/AAAA"
                  v-mask="'##/####'"
                  type="text"
                  class="h-12 border border-gray-300 rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                />
              </div>
              <div class="w-full flex gap-2">
                <input
                  v-model="payment.credit_debit_card.card_number"
                  placeholder="Número do Cartão"
                  v-mask="'#### #### #### #### #### #### #### ####'"
                  type="text"
                  class="h-12 border border-gray-300 rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                />
                <input
                  v-model="payment.credit_debit_card.security_code"
                  placeholder="Código de Segurança"
                  type="text"
                  class="h-12 border border-gray-300 rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                />
              </div>
            </div>
          </form>
          <div v-if="['credit_card', 'debit_card'].includes(payment.type) && !user.addresses.length" class="w-full flex flex-wrap p-4 pt-0 text-left gap-2">
            <p class="w-full text-center">
              Para pagar com Cartão de Crédito pela primeira vez, você precisa cadastrar um endereço.
            </p>
            <button
              @click="this.$router.push('customer')"
              class="w-full justify-center p-4 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase"
            >
              Cadastrar Endereço
            </button>
          </div>
          <button
            v-if="(['credit_card', 'debit_card'].includes(payment.type) && user.addresses.length) || ['pix', 'on_pickup', ''].includes(payment.type)"
            @click="handleSubmit"
            :disabled="disablePaymentSubmit"
            class="w-full disabled:bg-opacity-25 flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white uppercase"
          >
            Salvar
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useUserStore } from "~/stores/userStore"

export default {
  data() {
    return {
      user: useUserStore().getUser,
      is_open: false,
      payment: {
        type: '',
        credit_debit_card: {
          email: '',
          identification_type: '',
          tax_id: '',
          card_holder_name: '',
          expiration_date: '',
          card_number: '',
          security_code: '',
        },
      }
    };
  },
  props: {
    payment_methods: {
      type: Array
    },
    payment_methods_translation: {
      type: Object
    },
  },
  methods: {
    showModal() {
      this.is_open = true;
    },
    handleSubmit() {
      this.$emit('input-payment', this.payment);
      this.is_open = false;
    }
  },
  computed: {
    disablePaymentSubmit() {
      if (
        (
          (
            this.payment.type === 'credit_card' ||
            this.payment.type === 'debit_card'
          ) &&
          this.payment.credit_debit_card.card_holder_name.length > 3 &&
          this.payment.credit_debit_card.expiration_date.length === 7 &&
          this.payment.credit_debit_card.card_number.length > 3 &&
          this.payment.credit_debit_card.security_code.length >= 3 &&
          this.payment.credit_debit_card.email.length > 3 &&
          this.payment.credit_debit_card.identification_type.length &&
          this.payment.credit_debit_card.tax_id.length > 3
        ) ||
        (this.payment.type === 'pix') ||
        (this.payment.type === 'on_pickup')
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    'payment.type': function(value) {
      if (value === 'credit_card' || value === 'debit_card') {
        this.payment.credit_debit_card.email = this.user.email;
        this.payment.credit_debit_card.identification_type = this.user.tax_id.length ? this.user.tax_id.length > 14 ? 'CNPJ' : 'CPF' : '';
        this.payment.credit_debit_card.tax_id = this.user.tax_id;
      }
    },
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
