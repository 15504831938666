<template>
  <section class="bg-[#f4f3ef] flex flex-col p-8 items-center justify-center w-full h-full gap-4">
    <button
      @click="confirmPayment"
      class="w-full flex justify-center items-center gap-2 cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white rounded-md"
    > 
      Confirmar Pagamento
      <svg class="fill-[#fff] w-6" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
      </svg>
    </button>
    <modal-simple-text
      ref="ModalSimpleText"
      :show_footer="false"
    ></modal-simple-text>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { usePaymentStore } from "~/stores/paymentStore"
import ModalSimpleText from '@components/ModalSimpleText.vue';
moment.locale('pt-br');

export default {
  name: 'CreditDebitCardIndex',
  components: {
    ModalSimpleText
  },
  data() {
    return {
      payment: usePaymentStore().getPayment
    }
  },
  mounted() {
    this.handlePayment();
  },
  methods: {
    async handlePayment() {
      await axios.post("/api/checkout/create-payment-credit-debit-card-by-sale-order-id/" + this.payment.order_id, {
        ...this.payment.credit_debit_card
      })
        .then(({data}) => {
          if(data.status === 'CONFIRMED') {
            this.showSuccessMessage();
          } else {
            alert('Pagamento não foi confirmado. Por favor, tente novamente.');
            setTimeout(() => {
              this.$router.push({ name: 'OrdersView' })
            }, (3 * 1000));
          }
        });
    },
    async confirmPayment() {
      await axios.get("/api/checkout/get-by-sale-order-id/" + this.payment.order_id)
        .then(({data}) => {
          if(data.date_approved) {
            this.showSuccessMessage();
          } else {
            alert('Pagamento não foi confirmado. Por favor, tente novamente.');
          }
        });
    },
    showSuccessMessage() {
      this.$refs.ModalSimpleText.title = 'Sucesso';
      this.$refs.ModalSimpleText.message = 'Seu pedido foi agendado com sucesso!';
      this.$refs.ModalSimpleText.showModal()
      setTimeout(() => {
        this.$router.push({ name: 'OrdersView' })
      }, (3 * 1000));
    }
  },
}
</script>

<style>
</style>
