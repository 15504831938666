import moment from 'moment'

export default {
  methods: {
    handleDifferenceNowToMinimumHour(minimun_hour_in_same_day) {
      let date_to_compare = moment(new Date()).format('YYYY-MM-DD') + ' ' + minimun_hour_in_same_day + ':00';
      date_to_compare = new Date(date_to_compare);
      let date_now = new Date();
      return date_to_compare.getTime() < date_now.getTime();
    },
  }
};
