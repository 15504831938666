<template>
  <img
    class="h-fit rounded-full"
    :class="classes"
    src="/assets/logo.jpg"
  >
</template>
<script>
export default {
  name: 'LogoRounded',
  props: {
    classes: {
      type: String
    }
  }
}
</script>