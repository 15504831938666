<template>
  <main v-if="!loading" class="absolute w-full top-20 bottom-16">
    <section-confirmation/>
  </main>
</template>

<script>
import axios from 'axios';
import { useCompanySettingsStore } from "~/stores/companySettingsStore"
import { useDeliveryNeighborhoodsStore } from "~/stores/deliveryNeighborhoodsStore"
import { useUserStore } from "~/stores/userStore"
import SectionConfirmation from '@layouts/section-confirmation/Index.vue';

export default {
  components: {
    SectionConfirmation,
  },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    if(!useUserStore().getUser?.id) {
      this.$router.push({ name: 'SigninView' })
    } else {
      this.callFunctions();
    }
  },
  methods: {
    async callFunctions() {
      try {
        await this.fetchCompanySettings();
        await this.fetchNeighborhoods();
      } catch (error) {
        alert('Erro ao carregar dados. Por favor, contate-nos no Instagram: @monteseubolooficial e informe o erro');
        this.$router.push({ name: 'HomeView' })
      } finally {
        this.loading = false;
      }
    },
    async fetchCompanySettings() {
      return await axios.get("/api/get-company-settings")
      .then(response => {
        useCompanySettingsStore().setCompanySettings(response.data)
      }).catch(error => {
        throw error;
      });
    },
    async fetchNeighborhoods() {
      return await axios.get("/api/delivery/get-neighborhoods")
      .then(response => {
        useDeliveryNeighborhoodsStore().setDeliveryNeighborhoods(response.data);
      }).catch(error => {
        throw error;
      });
    },
  }
}
</script>