<template>
  <div class="flex flex-wrap justify-center flex-col items-center gap-4">
    <svg class="fill-[#f57f7f] w-6 hover:fill-[#82afd87f] transition-all" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M22.73 22.73L2.77 2.77 2 2l-.73-.73L0 2.54l4.39 4.39 2.21 4.66-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h7.46l1.38 1.38c-.5.36-.83.95-.83 1.62 0 1.1.89 2 1.99 2 .67 0 1.26-.33 1.62-.84L21.46 24l1.27-1.27zM7.42 15c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h2.36l2 2H7.42zm8.13-2c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H6.54l9.01 9zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2z"></path>
    </svg>
    <p>Ainda não foram escolhidos produtos</p>
    <a
      @click="this.$router.push('/')"
      class="justify-center cursor-pointer hover:bg-[#82afd87f] transition-all max-lg:border-b max-lg:py-2 bg-[#f57f7f] px-4 py-2 tracking-wider text-white block uppercase"
    > Voltar ao cardápio </a>
  </div>
</template>
<script>
export default {
  name: 'NoProductsSelected',
}
</script>