<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-customer/>
  </main>
</template>

<script>
import { useUserStore } from "~/stores/userStore"
import SectionCustomer from '@layouts/section-customer/Index.vue';

export default {
  components: {
    SectionCustomer,
  },
  mounted() {
    this.validateInitialTab();
  },
  methods: {
    validateInitialTab() {
      if(!useUserStore().getUser?.id) {
        this.$router.push({ name: 'SigninView' })
      }
    },
  }
}
</script>