<template>
  <div>
    <transition name="fade">
      <div v-if="is_open" class="fixed inset-0 flex justify-center items-center z-50 backdrop-blur-md">
        <div v-if="!loading" class="w-full md:w-[85vw] max-w-screen-xl p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
          <div class="w-full flex flex-wrap pt-2 overflow-y-auto text-left gap-4">
            <div class="w-full flex justify-between items-center px-4">
              <a class="text-[#f57f7f] text-xs md:text-base transition-all tracking-wider uppercase hover:text-[#f57f7f] pointer-events-none">
                Agendar para
              </a>
              <button
                @click="this.is_open = !this.is_open"
                class="mb-2 md:mb-0 bg-[#f57f7f] hover:bg-[#82afd87f] border-[#f57f7f] hover:border-[#82afd87f] border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg transition-all"
              >
                Fechar
              </button>
            </div>
          </div>
          <div class="w-full p-4">
            <FullCalendar :options="calendarOptions" />
          </div>
          <div v-if="time_to_produce.in_minutes" class="w-full flex flex-wrap p-4 pt-0 text-left">
            <p class="text-lg">
              Tempo estimado de {{ time_to_produce.in_hours }}h para produção
            </p>
          </div>
          <div v-if="company_settings.minimun_hour_in_same_day && time_to_produce.in_minutes > 0" class="w-full flex flex-wrap p-4 pt-0 text-left">
            <p v-if="company_settings.minimun_hour_in_same_day" class="text-sm">
              * Pedidos para o mesmo dia podem ser agendados entre {{ company_settings.minimun_hour_in_same_day }}h e o horário de fechamento do dia com no mínimo 3h de antecedência
            </p>
            <p v-else class="text-lg">
              * Pedidos para o mesmo dia podem ser agendados com no mínimo 3h de antecedência
            </p>
          </div>
          <div v-if="company_settings.minimun_hour_in_same_day && time_to_produce.in_minutes === 0" class="w-full flex flex-wrap p-4 pt-0 text-left">
            <p class="text-lg">
              * Pedidos pronta entrega podem ser agendados com no minimo 30 minutos de antecedência
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { useCompanySettingsStore } from "~/stores/companySettingsStore"
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import handleDifferenceNowToDateInDays from "~/mixins/handle-difference-now-to-date-in-days";
import handleDifferenceNowToDateInMinutes from "~/mixins/handle-difference-now-to-date-in-minutes";
import handleDifferenceNowToMinimumHour from "~/mixins/handle-difference-now-to-minimum-hour";
import handleDifferenceNowToMinimumMinute from "~/mixins/handle-difference-now-to-minimum-minute";
import handleMinutesToHours from "~/mixins/handle-minutes-to-hours";

export default {
  components: {
    FullCalendar
  },
  props: {
    cart: {
      type: Array
    }
  },
  data() {
    return {
      loading: true,
      is_open: false,
      arr_company_closed_hours: [],
      arr_business_hours: [],
      arr_filtered_events: [],
      initial_date: null,
      final_date: null,
      time_to_produce: {},
      company_settings: useCompanySettingsStore().getCompanySettings,
      opening_hours: { opening_time: '08:00', closing_time: '18:00' },
      weekdays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
    };
  },
  mixins: [
    handleDifferenceNowToDateInDays,
    handleDifferenceNowToDateInMinutes,
    handleDifferenceNowToMinimumHour,
    handleDifferenceNowToMinimumMinute,
    handleMinutesToHours
  ],
  mounted() {
    this.callFunctions();
  },
  methods: {
    async callFunctions() {
      try {
        await this.handleOpeningTime();
        await this.handleBusinessHours();
        await this.fetchSchedules();
        await this.handleTimeToProduce();
      } catch (error) {
        alert('Erro ao carregar dados. Por favor, contate-nos no Instagram: @monteseubolooficial e informe o erro');
        this.$router.push({ name: 'HomeView' })
      } finally {
        this.loading = false;
      }
    },
    async fetchSchedules(dates = {}) {
      this.arr_filtered_events = [];
      let initial_date = dates?.initial_date ? dates.initial_date : moment().format('YYYY-MM-DD');
      let final_date = dates?.final_date ? dates.final_date : moment().add(1, 'days').format('YYYY-MM-DD');
      return await axios.get("/api/schedules/get-schedules?initial_date=" + initial_date + "&final_date=" + final_date)
      .then(response => {
        this.handleSchedules(response.data);
      }).catch(error => {
        throw error;
      });
    },
    showModal() {
      this.is_open = true;
    },
    handleScheduleToDelivery(item) {
      let begin_time = moment(item).subtract(this.time_to_produce.in_minutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      let finish_time = moment(item).format('YYYY-MM-DD HH:mm:ss');
      if(this.arr_filtered_events.find(event => {
        let event_start_handled = moment(event.start).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        let event_end_handled = moment(event.end).subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        if(
          moment(event_start_handled).isBetween(begin_time, finish_time) ||
          moment(event_end_handled).isBetween(begin_time, finish_time)
        ) {
          return true;
        }
      })) {
        alert(`O pedido necessita de ${this.time_to_produce.in_hours}h para ser produzido. Selecione um próximo horário ou o próximo dia`);
      } else {
        this.$emit('input-schedule', {
          schedule: item,
          time_to_produce: this.time_to_produce.in_minutes
        });
        this.is_open = false;
      }
    },
    handleDateSelect(select_info) {
      if(moment(select_info.date).isAfter(new Date())) {
        if(this.handleDifferenceNowToDateInDays(select_info.date) === 0) {
          if(
            (
              this.company_settings.minimun_hour_in_same_day &&
              (
                (this.time_to_produce.in_minutes === 0 && this.handleDifferenceNowToMinimumMinute(30)) ||
                this.handleDifferenceNowToMinimumHour(this.company_settings.minimun_hour_in_same_day)
              ) &&
              this.handleDifferenceNowToDateInMinutes(select_info.date) >= 180
            ) ||
            this.handleDifferenceNowToDateInMinutes(select_info.date) >= 180
          ) {
            this.handleScheduleToDelivery(select_info.date);
          }
        } else {
          this.handleScheduleToDelivery(select_info.date);
        }
      }
    },
    handleOpeningTime() {
      let opening_time = 23;
      Object.values(JSON.parse(this.company_settings.opening_hours)).map((el) => {
        opening_time = Math.min(opening_time, parseInt(el.opening_time ? el.opening_time.split(':')[0] : '23'));
      });
  
      let closing_time = 0;
      Object.values(JSON.parse(this.company_settings.opening_hours)).map((el) => {
        closing_time = Math.max(closing_time, parseInt(el.closing_time ? el.closing_time.split(':')[0] : '00'));
      });

      this.opening_hours = { opening_time: `${("00" + opening_time).slice(-2)}:00`, closing_time: `${("00" + closing_time).slice(-2)}:00` };
    },
    handleBusinessHours() {
      let opening_hours = JSON.parse(this.company_settings.opening_hours);
      this.weekdays.forEach((weekday, index) => {
        if(opening_hours[weekday].opening_time && opening_hours[weekday].closing_time) {
          this.arr_business_hours.push({
            daysOfWeek: [index],
            startTime: opening_hours[weekday].opening_time,
            endTime: opening_hours[weekday].closing_time,
          });
        }
      });
    },
    handleSchedules(schedules) {
      schedules.forEach(schedule => {
        this.arr_filtered_events.push({
          start: schedule.start,
          end: schedule.end,
          display: "background",
          color: "#f57f7f"
        });
      });
    },
    handleDatesSet(dateInfo) {
      let start = new Date(dateInfo.start);
      let end = new Date(dateInfo.end);
      this.fetchSchedules({
        initial_date: moment(start).format('YYYY-MM-DD'),
        final_date: moment(end).format('YYYY-MM-DD')
      });
    },
    handleTimeToProduce() {
      let time_to_produce = 0;
      this.cart.forEach(product => {
        time_to_produce += (product.minutes_to_produce * (product.quantity ?? 1));
      });
      this.time_to_produce = {
        in_minutes: time_to_produce,
        in_hours: this.handleMinutesToHours(time_to_produce)
      };
    },
  },
  computed: {
    calendarOptions() {
      return {
        height: '60vh',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        locale: this.company_settings.language,
        headerToolbar: {
          left: "title",
          center: "",
          rigth: "prev,next",
        },
        buttonText: {
          today: 'Hoje',
          month: 'Mês',	
          day: 'Dia',
          list: 'Lista',
        },
        events: this.arr_filtered_events,
        datesSet: this.handleDatesSet,
        businessHours: this.arr_business_hours,
        initialView: 'timeGridDay',
        allDaySlot: false,
        slotDuration: "00:30:00",
        slotMinTime: this.opening_hours.opening_time,
        slotMaxTime: this.opening_hours.closing_time,
        showNonCurrentDates: false,
        eventDurationEditable: false,
        eventStartEditable: false,
        editable: false,
        selectable: false,
        slotEventOverlap: false,
        dateClick: this.handleDateSelect,
        selectConstraint: "businessHours",
        selectLongPressDelay: 0,
        selectOverlap: this.time_to_produce.in_minutes === 0
      };
    }
  }
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}

.fc-toolbar-title {
  font-size: 2vh !important;
}
@media screen and (min-width: 1200px) {
  .fc-toolbar-title {
    font-size: 1.75rem !important;
  }
}
</style>
