<template>
  <main class="absolute w-full top-20 bottom-16">
    <section-orders
      :sales_orders="sales_orders"
    />
  </main>
</template>

<script>
import axios from 'axios';
import { useUserStore } from "~/stores/userStore"
import SectionOrders from '@layouts/section-orders/Index.vue';

export default {
  components: {
    SectionOrders
  },
  data() {
    return {
      loading: true,
      sales_orders: []
    }
  },
  mounted() {
    if(!useUserStore().getUser?.id) {
      this.$router.push({ name: 'SigninView' })
    } else {
      this.callFunctions();
    }
  },
  methods: {
    async callFunctions() {
      try {
        await this.fetchSaleOrders();
      } catch (error) {
        alert('Erro ao carregar dados. Por favor, contate-nos no Instagram: @monteseubolooficial e informe o erro');
        this.$router.push({ name: 'HomeView' })
      } finally {
        this.loading = false;
      }
    },
    async fetchSaleOrders() {
      return await axios.get("/api/sales/get-sales-orders/" + useUserStore().getUser.id)
      .then(response => {
        this.sales_orders = response.data;
      }).catch(error => {
        throw error;
      });
    },
  }
}
</script>